import { darken } from 'polished';
import styled from 'styled-components';

interface IContainerProps {
  verifyExceededTime: () => boolean;
}

export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 120px;
  padding: 20px 10px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 10px;
  cursor: pointer;

  background-color: ${(props) =>
    props.verifyExceededTime() ? darken(0.025, '#eacc81') : 'white'};

  &:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  }
`;

export const Header = styled.h2`
  width: 100%;
  padding: 8px;
  font-weight: bold;
  height: fit-content;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const TagRow = styled.div`
  display: flex;
  padding: 0 6px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ContainerOrderNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 6px;
  width: fit-content;
  min-width: 80px;
  background-color: #2c2aa3;
  border-radius: 8px;
`;

export const TextOrderNumber = styled.span`
  font-weight: bold;
  font-size: 12px;
  color: white;
`;

export const TextTime = styled.p`
  font-size: 14px;
  text-align: right;
`;
