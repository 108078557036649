import React from 'react';
import { Flex, HStack, Text, Tooltip  } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-common-types';

interface ItemMenuProps {
  name: string;
  icon?: IconName;
  colapsed: boolean;
  action?: string;
  onClick?: () => void;
}

const ItemMenu = (data: ItemMenuProps) => {
  const { pathname } = useLocation();

  const isActive = (route: ItemMenuProps) => {
    return route.action === pathname;
  };

  return (
    <>
      <Tooltip label={data.colapsed ? data.name: null } aria-label="A tooltip">
        <HStack
          alignItems="center"
          key={ItemMenu+data.name}
          justifyContent="flex-start"
          as={!data.action ? 'div' : 'button'}
          w="100%"
          py={data.colapsed ? '3' : '2'}
          pl="2"
          onClick={() => (data.onClick ? data.onClick() : null)}
          style={{ transition: 'all 0.1s ease-in-out' }}
          bg={isActive(data) ? 'gray.700' : 'gray.800'}
          _hover={{
            backgroundColor: 'gray.900',
            borderRadius: '10px'
          }}
        >
          <Flex flexDirection="column">
            <HStack spacing="2" alignItems="center" justifyContent="center">
              {data.icon && 
                <FontAwesomeIcon icon={['fas', data.icon]} color="#FFFFFF" />
              }
              {!data.colapsed && (
                <Text color="#FFFFFF" mt="2.5">
                  {data.name}
                </Text>
              )}
            </HStack>
          </Flex>
        </HStack>
      </Tooltip>
    </>
  );
};

export default ItemMenu;
