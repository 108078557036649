import {
  Box, Button, Heading, HStack, Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack, Text, useDisclosure
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { IAudit } from '../../dtos/audit';
import api from '../../services/api';


type AuditFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IAudit) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const AuditModal = ({ dataId, action, onCloseForm }: AuditFormProps) => {
  const [objectData, setObjectData] = useState<IAudit>({} as IAudit);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse = await api.get(`/audit/${id}`);
    if (response && response.data) {
      const doc: IAudit = response.data
      setObjectData({
        ...doc,
        userAudit: doc.user ? doc.user.name : doc.userMobile?.name,
        object: doc.object || doc['objetc'] /* Registros antigos estavam sendo gravasos errados, no campo objetc, o campo foi corrigido mas os dados ainda não foram atualizados */
      });
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  function Field({ title, desc, isJson=false, ...rest }) {
    return (
      <Box p={1} shadow="md" borderWidth="1px" {...rest}>
        <Heading size="md" >{title}</Heading>
        {
          isJson ?
          <Box mt={1}>
            <pre>{JSON.stringify(desc, null, 2) }</pre>
          </Box> :
          <Text mt={1}>{desc }</Text>
        }
      </Box>
    )
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader textAlign="center" >
          {'Dados da auditoria'}
        </ModalHeader>
        <ModalBody pb={8} overflowX="auto">
            <Stack spacing="4">
              <HStack spacing={4}>
                <Field title="Entidade" flex="1" desc={objectData.entity} />
                <Field title="Operacao" flex="1" desc={objectData.operation} />
                <Field title="Origem"  flex="1" desc={objectData.source} />

              </HStack>
              <Field title="Transação" desc={objectData.transaction} />
              <Field title="Usuário" desc={objectData.userAudit} />
              <Field title="Mensagem" desc={objectData.log} />
              <Field title="Dados Alterados" isJson desc={objectData.object} />
            </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={() => closeForm(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AuditModal;
