/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IAssayResult } from '../../dtos/assay-result';
import AssayResultModal from './AssayResultModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const AssayResult = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [assayResultList, setAssayResultList] = useState<IAssayResult[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string>();

  
  
  const actionDelete = useCallback(async (item: IAssayResult) => {
    try {      
      const response: AxiosResponse = await api.delete(`/assayresult/${item._id}`);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Registro excluído com sucesso!',
        position: 'top-right',
      });
      
      getData({});
    } catch (error) {
      Toast({
        status: 'error',
        title: 'Erro ao excluir registro!',
        description: error?.message,
        position: 'top-right',
      });       
    }
  }, []);

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setAssayResultList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/assayresult/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: IAssayResult[] = response.data.rows || []
        setAssayResultList(docs.map((a: IAssayResult)=> ({...a, hasFile: ((a.files && a.files.length> 0)||(a.videos && a.videos.length> 0))})));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Objetivo', value: 'purpose', alignHeader: 'center', sortable: true },
      { text: 'Cultura', value: 'culture', align: 'center', sortable: true },
      { text: 'Safra', value: 'crop', alignHeader: 'center', sortable: true },
      { text: 'Publicado App', value: 'publishApp', type: 'boolean', align: 'center', sortable: true },
      { text: 'Arquivo', value: 'hasFile', type: 'boolean', align: 'center', sortable: true }
    ]);
    getData({});
  }, []);

  return (
    <>
      {showModal ? <AssayResultModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={assayResultList}
        filter={filterValue}
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        newItem={() => {
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionEdit={(item: IAssayResult) => {
          // console.log(item.name);
          setDataId(item._id);
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionView={(item: IAssayResult) => {
          setDataId(item._id);
          setActionModal('VIEW');
          setShowModal(true);
        }}
        itemActionDelete={async (item: IAssayResult) => {
          actionDelete(item);
        }}
        columnSortable={sortable}
      />
    </>
  );
};

export default AssayResult;
