/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IDataIntegrationEvent } from '../../dtos/data-integration-event';
import { Box, HStack, Input, Stack, Text } from '@chakra-ui/react';
import DateUtil from '../../utils/DataUtil';
import { IDataIntegrationSource } from '../../dtos/data-integration-source';
import Select from 'react-select';
import AppUtil from '../../utils/AppUtil';
import moment from 'moment';
import DataIntegrationEventModal from './DataIntegrationEventModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

interface IOptionsIntegrationSource {
  value: string;
  label: string;
}

interface IDataIntegrationEventDoc extends IDataIntegrationEvent {
  time?: string;
  sourceCode?: string;
  payloadLength?: number;
  payloadSize?: string;
}

const DataIntegrationEvent = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [dataIntegrationSourceList, setDataIntegrationEventList] = useState<IDataIntegrationEventDoc[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSortable] = useState<string|undefined>('-createdAt');

  const [integrationSourceList, setIntegrationSourceList] = useState<IOptionsIntegrationSource[]>([]);
  const [integrationSource, setIntegrationSource] = useState<IOptionsIntegrationSource>();
  const [initDate, setInitialDate] = useState<string>(DateUtil.stringFormatt(DateUtil.addDays(new Date(), -15), 'YYYY-MM-DD'));
  const [finishDate, setFinishDate] = useState<string>(DateUtil.stringFormatt(new Date(), 'YYYY-MM-DD'));
  
  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (initDate){
        query.push(`initialdate=${ moment(initDate).toJSON() }`);
      }
      if (finishDate){
        query.push(`finishdate=${ moment(finishDate).toJSON() }`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
      
      query.push(`sort=${sort||'-createdAt'}`);
      
      setSortable(sort||'-createdAt');
      setDataIntegrationEventList([]);
      setAllRows(0);
      if (integrationSource && integrationSource.value && integrationSource.value != 'X'){        
        const response: AxiosResponse = await api.get(`/dataintegration/${integrationSource?.value}/event/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
        if (response && response.data) {
          const docs: IDataIntegrationEvent[] = response.data.rows || []
          setDataIntegrationEventList(docs.map(doc => ({
            ...doc, 
            createdAt: moment(doc.createdAt).format('DD/MM/YYYY HH:mm:ss.SS'),
            type: doc.type === 'AUTH'? 'Autenticação' : doc.type === 'DATA' ? 'Envio Dados': 'Consulta Versão',
            status: statusText(doc.status),
            sourceCode: doc.integrationSource?.code,
            payloadLength: doc.payload && Array.isArray(doc.payload) ? doc.payload.length : 0,  
            payloadSize: AppUtil.memorySizeOf(doc?.payload),
            time: doc.finishedAt && doc.startedAt ? DateUtil.timeTxt(doc.startedAt, doc.finishedAt) : ''
          })));
          setAllRows(response.data.allRows);
        }
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  

  function statusText(status: string): string {
    if (status === 'PENDING'){
      return 'Req. Incompleta'
    } else if (status === 'SUCCESS'){
      return 'Sucesso'
    } else if (status === 'ERROR'){
      return 'Erro'
    } else {
      return status+'-Indefinido'
    }
  }
  const getIntegrationSource = async () => {
    try {
      setLoading(true);      
      setIntegrationSourceList([]);
      const response: AxiosResponse = await api.get(`/dataintegration/?limit=15&sort=createdAt`);
      if (response && response.data) {
        const docs: IDataIntegrationSource[] = response.data || []
        let options: IOptionsIntegrationSource[] = docs.filter(f=> !!f.active)?.map(d=> ({value: d._id, label: d.code}))
        if (options && options.length === 0){
          options = [{
            value: 'X',
            label: 'Nenhuma Integração Ativa'
          }]
        }
        setIntegrationSource(options[0])
        setIntegrationSourceList(options);        
      }
    } catch (e) {
      console.error('getIntegrationSource. Error: ', e)
    }    
    getData({});
  };
  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
      
    setHeaders([
      { text: 'Data', value: 'createdAt', align: 'center', type: 'datetime', cellClass: {w: '20%', minW: '200px'}, sortable: true },
      { text: 'Fonte', value: 'sourceCode', align: 'center', cellClass: {w: '10%'}, sortable: false },
      { text: 'Entidade', value: 'entity', align: 'center', cellClass: {w: '13%'}, sortable: true },
      { text: 'Tipo', value: 'type', align: 'center', sortable: true },
      { text: 'Status', value: 'status', align: 'center', cellClass: {w: '10%'}, sortable: true },
      { text: 'Tempo', value: 'time', align: 'end', alignHeader: 'center', cellClass: {w: '10%'}, sortable: false },
      { text: 'Qt. Registros', value: 'payloadLength', align: 'end', alignHeader: 'center', cellClass: {w: '10%'}, sortable: false },
      { text: 'Tamanho', value: 'payloadSize', align: 'end', alignHeader: 'center', cellClass: {w: '10%'}, sortable: false },
    ]);
    getIntegrationSource()
  }, []);

  useEffect(() => {
    getData({});
  }, [initDate, finishDate, integrationSource]);
  return (
    <>
      {showModal ? <DataIntegrationEventModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null }
      <Stack d="flex" w="100%">
       <HStack>
        <Input
            placeholder="Data Inicial"
            type='date'
            maxW="200px"
            minW="180px"
            value={initDate}
            onChange={(event) => {
              setInitialDate(event.target.value);
            }}
          />
          <Input
            placeholder="Data Final"
            type='date'
            maxW="200px"
            minW="180px"
            value={finishDate}
            onChange={(event) => {              
              setFinishDate(event.target.value);              
            }}
          />
          <Text>Fonte: </Text>
          <Box w="100%">
            <Select
              placeholder="Pesquisa"
              options={integrationSourceList}
              value={integrationSource}
              onChange={(event) => {
                setIntegrationSource(event as IOptionsIntegrationSource);
              }}
            />
          </Box>
          
        </HStack>
        <DataTableComponent
          isLoading={isLoading}
          headers={headers}
          itens={dataIntegrationSourceList}
          filter={filterValue}
          paginationOptions={{
            allItems: allRows,
            itemsPerPage: rowsPerPage,
            page: currentPage,
          }}
          tableSync={(filter, sort, page, offset, limit) => {
            getData({ filter, sort, page, offset, limit });
          }}
          itemActionView={(item: IDataIntegrationEvent) => {
            setDataId(item._id);
            setActionModal('VIEW');
            setShowModal(true);
          }}
          columnSortable={sortable}
        />
      </Stack>
    </>
  );
};

export default DataIntegrationEvent;
