import {  SimpleGrid,  SkeletonText, Stack, useToast, HStack, Button, Text } from "@chakra-ui/react"
import { FormHandles } from "@unform/core";
import * as Yup from 'yup';
import React, { useCallback, useEffect, useRef, useState } from "react"
import { ObjectShape } from "yup/lib/object";
import EAForm from "../../components/Form/EAForm";
import EAInput from "../../components/Form/EAInput";
import api from "../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave } from "@fortawesome/free-solid-svg-icons";
import { AxiosResponse } from "axios";

interface IParameterWarehouse {
  maxItensQuantity?: number
  maximumWeight?: number
  uniqueMaximumWeight?: number
}
const yupSchema = {
  /*
  maxItensQuantity: Yup.number(),
  maximumWeight: Yup.number(),
  uniqueMaximumWeight: Yup.number()
  */
}
const ParameterWarehouse = () => {
  
  const Toast = useToast();
  const formRef = useRef<FormHandles>(null);
  const [formSchema] = useState<ObjectShape>(yupSchema);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<IParameterWarehouse>({});

  
  const getDocument = async () => {
    setLoading(true)
    const strParams = 'maxItensQuantity,maximumWeight,uniqueMaximumWeight';
    const response = await api.get<IParameterWarehouse>(`/parameter/get?parameters=${strParams}`);
    const { data } = response
    setData(data)
    
    setLoading(false)
  };

  useEffect(() => {
    async function getDataFromAPI() {
      await getDocument();
    }
    getDataFromAPI();
  }, []);
  
  const setInputValue = useCallback(async (value: any, field: string) => {
    data[field] = value
    setData(data)
  }, []);

  const save = async ({maxItensQuantity, maximumWeight, uniqueMaximumWeight}: IParameterWarehouse) => {
    try {
      setSaving(true)
      const dataToSave = {
        maxItensQuantity: maxItensQuantity ? parseInt(String(maxItensQuantity)): null,
        maximumWeight: maximumWeight ? parseInt(String(maximumWeight)): null,
        uniqueMaximumWeight: uniqueMaximumWeight ? parseInt(String(uniqueMaximumWeight)): null
      };
      const saved: AxiosResponse<IParameterWarehouse> = await api.post<IParameterWarehouse>('/parameter/save', dataToSave);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Parâmestros gravados com sucesso!',
        position: 'top-right',
      });
      getDocument();
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err.message ,
        position: 'top-right',
      });
    } finally{
      setSaving(false)
    }
  }

  return (    
    <Stack w={'100%'}>  
      <EAForm formRef={formRef} onSubmit={save} yupSchema={formSchema}>
      <SimpleGrid columns={2} spacing={10}>
        { loading ?
          <SkeletonText mt='4' noOfLines={8} spacing='4' skeletonHeight='12'  w={'100%'} /> :
          <Stack maxW={'50%'} border={"1px"} borderRadius="15" p="4">
            <Text>
              Número de Itens Máximo e Peso Máximo
            </Text>
            <HStack spacing={2}>
              <EAInput
                name="maxItensQuantity"
                type='number'
                defaultValue={ data?.maxItensQuantity }
                onChange={($event)=> setInputValue($event.target.value, 'maxItensQuantity')}
                autoComplete="none"
                textAlign="right"
                inputRightAddon="UN"
              />
              <EAInput
                name="maximumWeight"
                type='number'
                defaultValue={ data?.maximumWeight }
                onChange={($event)=> setInputValue($event.target.value, 'maximumWeight')}
                autoComplete="none"
                textAlign="right"
                inputRightAddon="KG"
              />
            </HStack>
            <Text>
              Considerar peso máximo para embarque unico
            </Text>
            <EAInput
              name="uniqueMaximumWeight"
              type='number'
              defaultValue={ data?.uniqueMaximumWeight }
              onChange={($event)=> setInputValue($event.target.value, 'uniqueMaximumWeight')}
              autoComplete="none"
              textAlign="right"
              inputRightAddon="KG"
            />
          </Stack>
        }    
      </SimpleGrid>
      <Button 
        isDisabled={saving} 
        position={'absolute'}        
        bottom="4"
        right="4"
        colorScheme="green" mr={3} 
        leftIcon={<FontAwesomeIcon icon={faSave} />}
        onClick={formRef.current?.submitForm}>
        Salvar
      </Button>
      </EAForm>
    </Stack>
  )
};

export default ParameterWarehouse;
