import React from 'react';
import { DeliveryOrderBoardDTO } from '../../dtos/delivery-order';
import { KeyValueFromEnum } from '../../dtos/key-value-from-enum';
import AppUtil from '../../utils/AppUtil';
import { BoardCard } from '../BoardCard';
import { Section, SectionBody, SectionHeader } from './styles';

interface IBoardSectionProps {
  state: KeyValueFromEnum;
  data: DeliveryOrderBoardDTO[];
}

export function BoardSection({ state, data }: IBoardSectionProps) {
  return (
    <Section key={state.key}>
      <SectionHeader>{state.value}</SectionHeader>
      <SectionBody>
        {data.map(
          (d, index) =>
            d.status === state.key && (
              <BoardCard
                key={`${d.code}-${AppUtil.randomId()}`}
                data={d}
                index={index}
              />
            ),
        )}
      </SectionBody>
    </Section>
  );
}
