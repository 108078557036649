import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  HStack,
  Text,
  Heading,
  Box
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IDataIntegrationEvent } from '../../dtos/data-integration-event';
import moment from 'moment';
import DateUtil from '../../utils/DataUtil';
import AppUtil from '../../utils/AppUtil';


type DataIntegrationEventFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IDataIntegrationEvent) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const DataIntegrationEventModal = ({ dataId, action, onCloseForm }: DataIntegrationEventFormProps) => {
  const [objectData, setObjectData] = useState<IDataIntegrationEvent>({} as IDataIntegrationEvent);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse = await api.get(`/dataintegration/${id}/event/${id}`);
    if (response && response.data) {
      const doc: IDataIntegrationEvent = response.data
      setObjectData({
        ...doc
      });
    }
  };
  
  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  function statusText(status: string): string {
    if (status === 'PENDING'){
      return 'Req. Incompleta'
    } else if (status === 'SUCCESS'){
      return 'Sucesso'
    } else if (status === 'ERROR'){
      return 'Erro'
    } else {
      return status+'-Indefinido'
    }
  }
  function Field({ title, desc, isJson=false, ...rest }) {
    return (
      <Box p={1} shadow="md" borderWidth="1px" {...rest}>
        <Heading size="md" >{title}</Heading>
        {
          isJson ?
          <Box mt={1}>
            <pre>{JSON.stringify(desc, null, 2) }</pre>
          </Box> : 
          <Text mt={1}>{desc }</Text>
        }
      </Box>
    )
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader textAlign="center" >
          {'Dados do Log de Integração'}
        </ModalHeader>        
        <ModalBody pb={8} overflowX="auto">          
            <Stack spacing="4">
              <HStack spacing={4}>
                <Field title="Origem"  flex="1" desc={objectData.integrationSource?.code} />
                <Field title="Entidade" flex="1" desc={objectData.entity} />
                <Field title="Operacao" flex="1" desc={objectData.type === 'AUTH'? 'Autenticação' : objectData.type === 'DATA' ? 'Envio Dados': 'Consulta Versão'} />

              </HStack>
              <HStack spacing={4}>
                <Field title="Status"  flex="1" desc={statusText(objectData.status) } />
                <Field title="Inicio Transação" flex="1" desc={ moment(objectData.startedAt).format('DD/MM/YYYY HH:mm:ss.SSS') } />
                <Field title="Fim Transação" flex="1" desc={ objectData.finishedAt && moment(objectData.finishedAt).format('DD/MM/YYYY HH:mm:ss.SSS') } />
                <Field title="Tempo" flex="1" desc={ objectData.startedAt && objectData.finishedAt && DateUtil.timeTxt(objectData.startedAt, objectData.finishedAt) } />
              </HStack>
              <Field title="Mensagem" desc={objectData.message} />
              <HStack spacing={4}>
                <Field title="Quantidade Registros" flex="1" desc={ objectData.payload && Array.isArray(objectData.payload) ? objectData.payload.length : 0 } />
                <Field title="Tamanho estimado" flex="1" desc={ AppUtil.memorySizeOf(objectData?.payload) } />
              </HStack>
              {objectData.errorDetail ?
                <HStack spacing={4} alignItems="baseline" w="100%" >
                  <Field title="Dados Alterados" isJson w="50%" desc={objectData.payload} />
                  <Field title="Detalhe de Erro" isJson w="50%" desc={objectData.errorDetail} />
                </HStack>
                : objectData.payload && <Field title="Dados Alterados" isJson  w="100%" desc={objectData.payload} />
              }
            </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={() => closeForm(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DataIntegrationEventModal;
