import React from 'react';

import {
  Container,
  ContainerBackdrop,
  Loading as LoadingStyle,
  Title,
} from './styles';

interface ILoadingProps {
  open?: boolean;
  color?: string;
}

const LoadingBackdrop: React.FC<ILoadingProps> = ({ open, color }) => (
  <>
    {open && (
      <ContainerBackdrop>
        <Container>
          <LoadingStyle color={color || '#3182ce'} />
          <Title>Aguarde...</Title>
        </Container>
      </ContainerBackdrop>
    )}
  </>
);
export default LoadingBackdrop;
