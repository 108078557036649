function translate(key: string, count: number) {
  if (key === 'daysAgo') return `há ${count} dias`;
  if (key === 'dayAgo') return `há ${count} dia`;
  if (key === 'hoursAgo') return `há ${count} horas`;
  if (key === 'hourAgo') return `há ${count} hora`;
  if (key === 'minAgo') return `há ${count} min`;
  if (key === 'days') return ` ${count} dias`;
  if (key === 'day') return ` ${count} dia`;
  if (key === 'hours') return ` ${count} horas`;
  if (key === 'hour') return ` ${count} hora`;
  if (key === 'min') return ` ${count} min`;
}

export default (
  dataInicial: number,
  dataFinal?: number,
  showAgo = true,
): string => {
  // console.log(dataInicial);
  const ago = showAgo ? 'Ago' : '';
  const finalDate = dataFinal || new Date().getTime();
  const diffMs = finalDate - dataInicial;
  const diffDays = Math.floor(diffMs / 86400000);
  const diffHrs = Math.floor((diffMs % 86400000) / 3600000);
  const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);

  let str = '';
  if (diffDays > 0) {
    return (str += translate(
      `${diffDays > 1 ? `days${ago}` : `day${ago}`}`,
      diffDays,
    ));
  }
  if (diffHrs > 0) {
    return (str += translate(
      `${diffHrs > 1 ? `hours${ago}` : `hour${ago}`}`,
      diffHrs,
    ));
  }
  return (str += translate(`min${ago}`, diffMins));
};
