import React from 'react';
import { Flex, Spinner } from '@chakra-ui/react';

const SplashScreen = () => {
  return (
    <Flex w="100%" h="100%" alignItems="center" justifyContent="center">
      Carregando...
      <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
    </Flex>
  );
};

export default SplashScreen;
