import { Flex } from '@chakra-ui/react';
import React from 'react';
import ECoop from '../../assets/images/ECoop/e-coop_logo-01.svg';
const Home = () => {
  return (
    <Flex w="100%" h="100%" display={'block'} mt={'auto'}>
      <Flex
        alignSelf={'flex-end'}
        justifyContent={'end'}
        position={'fixed'}
        bottom={0}
        right={0}
        pb={4}
        pr={6}
      >
        <Flex w="25vw">
          <img src={ECoop} />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Home;
