import React from 'react';
import { Switch } from 'react-router-dom';
import Home from '../pages/Home';
import SignIn from '../pages/SignIn';

import { SidebarProvider } from '../hooks/Sidebar';
import AssayResultComponent from '../pages/AssayResult';
import AuditComponent from '../pages/Audit';
import { DashboardRetiradas } from '../pages/DashboardRetiradas';
import DataIntegrationEvent from '../pages/DataIntegrationEvent';
import DataIntegrationSource from '../pages/DataIntegrationSource';
import DeliveryOrder from '../pages/DeliveryOrder';
import EventMobileComponent from '../pages/EventMobile';
import NotificationSend from '../pages/NotificationSend';
import { ParametersGeneral } from '../pages/ParametersGeneral';
import { ParametersStock } from '../pages/ParametersStock';
import UserComponent from '../pages/User';
import UserMobileComponent from '../pages/UserMobile';
import Route from './Route';
import NewsManagement from '../pages/NewsManagement';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} key={'/'} />
    <SidebarProvider>
      <Route path="/home" isPrivate component={Home} key={'/home'} />
      <Route
        path="/register/user"
        isPrivate
        component={UserComponent}
        key={'/register/user'}
      />
      <Route
        path="/register/usermobile"
        isPrivate
        component={UserMobileComponent}
        key={'/register/usermobile'}
      />
      <Route
        path="/assay/result"
        isPrivate
        component={AssayResultComponent}
        key={'/assay/result'}
      />
      <Route
        path="/tools/parameters/stock"
        isPrivate
        component={ParametersStock}
        key={'/tools/parameters/stock'}
      />
      <Route
        path="/tools/parameters/general"
        isPrivate
        component={ParametersGeneral}
        key={'/tools/parameters/general'}
      />
      <Route
        path="/tools/audit"
        isPrivate
        component={AuditComponent}
        key={'/tools/audit'}
      />
      <Route
        path="/tools/eventmobile"
        isPrivate
        component={EventMobileComponent}
        key={'/tools/eventmobile'}
      />
      <Route
        path="/tools/integration"
        isPrivate
        component={DataIntegrationSource}
        key={'/tools/integration'}
      />
      <Route
        path="/tools/integration-audit"
        isPrivate
        component={DataIntegrationEvent}
        key={'/tools/integration-audit'}
      />
      <Route
        path="/tools/notification-send"
        isPrivate
        component={NotificationSend}
        key={'/tools/notification-send'}
      />
      <Route
        path="/tools/news-management"
        isPrivate
        component={NewsManagement}
        key={'/tools/news-management'}
      />
      <Route
        path="/dashboard"
        isPrivate
        component={DashboardRetiradas}
        key={'/dashboard'}
      />
      <Route
        path="/delivery-order"
        isPrivate
        component={DeliveryOrder}
        key={'/delivery-order'}
      />
    </SidebarProvider>
  </Switch>
);

export default Routes;
