/* eslint-disable no-nested-ternary */
import { Box, Flex, Stack } from '@chakra-ui/react';
import React, { createContext, useCallback, useContext, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header';
import ItemMenu from '../components/Sidebar/ItemMenu';
import ItemMenuGroup from '../components/Sidebar/ItemMenuGroup';
import menuList from '../config/Menus';
import { IMenu } from '../dtos/commons';
import { ISession } from '../dtos/user';
import { useAuth } from './AuthContext';

interface SidebarContextData {
  menus: Array<IMenu>;
  setHideSideBar(hide: boolean): void;
  hideSideBar?: boolean
}

const SidebarContext = createContext<SidebarContextData>({
  menus: new Array<IMenu>(),
} as SidebarContextData);

const SidebarProvider = ({ children }) => {
  const [colapsed, setColapsed] = useState<boolean>(false);
  const [session] = useState<ISession>(useAuth().session);
  const [hideSideBar, setHideSideBar] = useState<boolean>(false);
  // console.log('SidebarProvider - session: ', session);
  const [menus] = useState<Array<IMenu>>(
    menuList
      .map((doc: IMenu) => ({
        ...doc,
        submenus: doc.submenus?.filter(
          (mm) => mm.action && (!mm.permission || (session.permissions && session.permissions.map(pp=> pp.permission).includes(mm.permission))),
        ),
      }))
      .filter(
        (p) =>
          (p.submenus && p.submenus?.length > 0) ||
          (p.action && !p.submenus && !p.permission) ||
          (p.action && p.permission && session.permissions.map(pp=> pp.permission).includes(p.permission)),
      ),
  );
  // console.log('SidebarProvider - menus: ', menus);

  const montaMenus = () => {
    const sortMenu = menus.sort((a, b) => (a.order && b.order ? (a.order > b.order ? 1 : -1) : 1));
    return sortMenu.map((menu) => (
      <Box key={`${menu.title}-${menu.action}`}>
        {menu.action ? (
          <NavLink to={menu.action} key={`${menu.title}-${menu.action}`}>
            <ItemMenu colapsed={colapsed} name={menu.title} icon={menu.icon} action={menu.action} key={`${menu.title}-${menu.action}`}/>
          </NavLink>
        ) : (
          <ItemMenuGroup
            colapsed={colapsed}
            name={menu.title}
            icon={menu.submenus && menu.submenus.length ? menu.icon : undefined}
            onClick={undefined}
            submenus={menu.submenus}
            key={`${menu.title}-${menu.action}`}
          />
        )}
      </Box>
    ));
  };

  return (
    <SidebarContext.Provider value={{menus, setHideSideBar, hideSideBar}}>
      <Header menuAction={() => setColapsed(!colapsed)} />
      <Flex
        // w="100vw"
        h="93vh"
        minW="100%"
        // justifyContent="space-between"
        flexDirection="row"
        style={{ transition: 'all 0.2s ease-in-out' }}
      > 
        { !hideSideBar ? 
          <Flex w={colapsed ? '56px' : '300px'} flexDirection="column" justifyContent="space-between" bg="gray.800" key={'flex-menus-options'}>
            <Stack justifyContent="flex-start" spacing="-0.5">
              {montaMenus()}
            </Stack>
          </Flex>
         : null}        
        <Flex w="100%" /* w={colapsed ? '96%' : '90%'} */ p="4" key={'flex-menus-colapsed'+children}>
          {children}
        </Flex>
      </Flex>
    </SidebarContext.Provider>
  );
};

function useSidebar() {
  const context = useContext(SidebarContext);

  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }

  return context;
}

export { SidebarProvider, useSidebar };
