/* eslint-disable no-underscore-dangle */
import React, { useCallback, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { INewsManagement } from '../../dtos/NewsManagement';
import NewsManagementModal from './NewsManagementModal';
import moment from 'moment';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const NewsManagement = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [newsManagementList, setNewsManagementList] = useState<INewsManagement[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string|undefined>('-createdAt');

  
  
  const actionDelete = useCallback(async (item: INewsManagement) => {
    try {      
      const response: AxiosResponse = await api.delete(`/news-management/delete/${item._id}`);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Registro excluído com sucesso!',
        position: 'top-right',
      });
      
      getData({});
    } catch (error) {
      Toast({
        status: 'error',
        title: 'Erro ao excluir registro!',
        description: error?.message,
        position: 'top-right',
      });       
    }
  }, []);

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setNewsManagementList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/news-management/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: INewsManagement[] = response.data.rows || []
        setNewsManagementList(docs.map((a: INewsManagement)=> ({...a, createdAt: moment(new Date(a.createdAt)).format('DD/MM/YYYY HH:mm:ss'), hasAttachment: (a.attachments && a.attachments.length> 0)})));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Data', value: 'createdAt', align: 'center', cellClass: {w: '20%', minW: '200px'}, sortable: true },
      { text: 'Titulo', value: 'title', alignHeader: 'center', sortable: true },
      { text: 'Destino', value: 'type', align: 'center', cellClass: {w: '10%', minW: '100px'}, sortable: true },
      { text: 'Arquivo', value: 'hasAttachment', type: 'boolean', align: 'center', cellClass: {w: '10%', minW: '100px'}, sortable: false }
    ]);
    getData({});
  }, []);

  return (
    <>
      {showModal ? <NewsManagementModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={newsManagementList}
        filter={filterValue}
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        newItem={() => {
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionEdit={(item: INewsManagement) => {
          // console.log(item.name);
          setDataId(item._id);
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionView={(item: INewsManagement) => {
          setDataId(item._id);
          setActionModal('VIEW');
          setShowModal(true);
        }}
        itemActionDelete={async (item: INewsManagement) => {
          actionDelete(item);
        }}
        columnSortable={sortable}
      />
    </>
  );
};

export default NewsManagement;
