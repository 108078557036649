import { Heading,  SkeletonText, Stack } from "@chakra-ui/react"
import React from "react"

const ParameterGeneral = () => {
  return (   
    <Stack w={'100%'}>
      <Heading>Pagina em construção...</Heading>
      <SkeletonText mt='4' noOfLines={5} spacing='4' skeletonHeight='12'  w={'100%'} />
    </Stack>
  )
};

export default ParameterGeneral;
