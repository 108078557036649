import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Input, InputGroup, InputLeftAddon, InputLeftElement, InputProps, InputRightAddon, InputRightElement } from '@chakra-ui/input';
import { FormHandles, useField } from '@unform/core';
import { darken } from 'polished';
import React, { useCallback, useEffect, useRef } from 'react';
import validateEmail from '../../../utils/validateEmail';
import { CEP, CNPJ, CPF, CPFCNPJ, CURRENCY } from './mask';

interface EAInputProps extends InputProps {
  name: string;
  label?: string;
  isInvisible?: boolean;
  mask?: 'CPF' | 'CNPJ' | 'CEP' | 'CURRENCY' | 'CPFCNPJ' | 'EMAIL' | undefined;
  inputLeftElement?: React.ReactNode
  inputRightElement?: React.ReactNode
  inputLeftAddon?: string
  inputRightAddon?: string
}

const EAInput: React.FC<EAInputProps> = ({ name, label, isReadOnly, isRequired, isInvisible, mask, inputLeftElement, inputRightElement, inputLeftAddon, inputRightAddon, ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const formRef = useRef<FormHandles>(null);
  const { fieldName, error, registerField } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleKeyUp = useCallback((e: React.FormEvent<HTMLInputElement>) => {
      if (mask === 'CEP') {
        CEP(e);
      }
      if (mask === "CURRENCY") {
        CURRENCY(e);
      }
      if (mask === 'CPF') {
        CPF(e);
      }
      if (mask === 'CNPJ') {
        CNPJ(e);
      }
      if (mask === 'CPFCNPJ') {
        CPFCNPJ(e);
      }
      if(mask === 'EMAIL') {
        let err = validateEmail(e.currentTarget.value);
        console.log(err)
        console.error('Arrumar validação de email')
        // TODO: Arrumar validação de email
        // formRef.current?.setFieldError(fieldName, err ? 'Email Inválido': '' )
        // inputRef.current?.err
        // setIsValid(err);
      }
    },
    [mask]
  );
  if (isInvisible){
    return null
  } else {
    return (
      <FormControl isInvalid={!!error} isRequired={isRequired} isReadOnly={isReadOnly} >        
        <FormLabel htmlFor={"input-"+fieldName} size="xs">{label}</FormLabel>
        { inputLeftElement || inputRightElement ?
          <InputGroup>
            {inputLeftElement ? <InputLeftElement children={inputLeftElement} />: null }
            <Input
              id={"input-"+fieldName }
              ref={inputRef}
              size="md"
              color={(isReadOnly ? darken(0.2,'#666360') : '#41414A')}
              background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
              onKeyUp={handleKeyUp}            
              {...props}
            />
            {inputRightElement ? <InputRightElement children={inputRightElement} />: null }
          </InputGroup>
        :
          inputLeftAddon || inputRightAddon ?
          <InputGroup>
            {inputLeftAddon ? <InputLeftAddon children={inputLeftAddon} />: null }
            <Input
              id={"input-"+fieldName }
              ref={inputRef}
              size="md"
              color={(isReadOnly ? darken(0.2,'#666360') : '#41414A')}
              background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
              onKeyUp={handleKeyUp}            
              {...props}
            />
            {inputRightAddon ? <InputRightAddon children={inputRightAddon} />: null }
          </InputGroup>
          :
          <Input
            id={"input-"+fieldName }
            ref={inputRef}
            size="md"
            color={(isReadOnly ? darken(0.2,'#666360') : '#41414A')}
            background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
            onKeyUp={handleKeyUp}            
            {...props}
          />
        }          
        <FormErrorMessage>{error}</FormErrorMessage>
      </FormControl>
    )
  }
};

export default EAInput;
