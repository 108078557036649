import { KeyValueFromEnum } from '../dtos/key-value-from-enum';

export const extractKeyValueFromEnum = (paramEnum: any): KeyValueFromEnum[] =>
  Object.keys(paramEnum).map((objKey) => ({
    key: objKey,
    value: paramEnum[objKey],
  }));

export const extractKeyAndValueFromEnum = (
  paramEnum: any,
  key: string,
): KeyValueFromEnum => {
  return {
    key: key,
    value: paramEnum[key],
  };
};
