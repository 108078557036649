import moment from 'moment'

export default class DateUtil {
  public static trunc (value: Date): Date {
    return new Date(value.setHours(0, 0, 0, 0))
  }

  public static truncCeil (value: Date): Date {
    return new Date(value.setHours(24, 0, 0, 0))
  }

  public static addDays (value: Date, days:number = 1): Date {
    return new Date(value.setDate(value.getDate() + days))
  }

  public static stringFormatt (value: Date, mask:string = 'YYYY-MM-DD HH:mm:ss'): string {
    return moment(value).format(mask)
  }

  public static isEquals (value: Date, value2: Date): boolean {
    return moment(value).isSame(value2)
  }

  public static isEqualsDay (value: Date, value2: Date): boolean {
    return moment(this.trunc(value)).isSame(this.trunc(value2))
  }

  public static isValid (value: any): boolean {
    return moment(value).isValid()
  }

  public static timeMs (start: Date, end: Date): number {
    if (start && end){
      // return moment(end).millisecond() - moment(start).millisecond()  
      return new Date(end).getTime() - new Date(start).getTime()
    } else {
      return 0;
    }
  }

  public static timeTxt (start: Date, end: Date): string {
    if (start && end){
      const time: number = this.timeMs(start, end)
      if (Math.abs(time)<1000){
        return `${time} ms`
      } else if (Math.abs(time) > 1000 && Math.abs(time) < 60000){
        return `${(time/1000).toFixed(2)} s`
      } else {
        return `${(time/60000).toFixed(2)} m`
      }
    } else {
      return '';
    }
  }
}
