import React, { useRef, useState } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertDialogProps } from '@chakra-ui/modal';
import { Button, ButtonProps } from '@chakra-ui/button';
import EATextarea from '../Form/EATextarea';

interface EATextBoxDialogProps {
  isOpenDialog: boolean
  textHeader?: string
  label: string
  value?: string
  required?: boolean
  textActionOne?: string
  textActionTwo?: string
  textActionThree?: string
  actionOneProps ?: ButtonProps
  actionOne?: (valeu: any) => void;
  actionTwoProps ?: ButtonProps
  actionTwo?: (valeu: any) => void;
  actionThreeProps ?: ButtonProps
  actionThree?: (valeu: any) => void;
}

const EATextBoxDialog: React.FC<EATextBoxDialogProps> = (props) => {
  const { 
    textHeader, 
    label, 
    value,
    required,
    textActionOne, 
    textActionTwo, 
    textActionThree, 
    actionOneProps = {}, 
    actionOne = (value) => {},
    actionTwoProps = {},
    actionTwo = (value) => {},
    actionThreeProps = {}, 
    actionThree = (value) => {},
    isOpenDialog,
    } = props
  const actionRef = useRef(null)
  const [textBox, setTextBox] = useState<string|undefined>(value);
  return (    
    <AlertDialog
      isOpen={isOpenDialog}
      leastDestructiveRef={actionRef}
      onClose={()=>{}}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {textHeader || 'Confirmação!'}
          </AlertDialogHeader>

          <AlertDialogBody>
            <EATextarea   
                label={label}           
                name="textBox"
                isRequired={required}
                defaultValue={textBox||''}
                autoComplete="none"
                onChange={($event)=> setTextBox($event.target.value)}
              />
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={actionRef} onClick={()=> actionOne(textBox) } {...actionOneProps}>
              {textActionOne || 'Ok'}
            </Button>
            { textActionTwo &&  <Button onClick={()=> actionTwo(textBox) } {...actionTwoProps} ml={3} >{textActionTwo} </Button> }
            { textActionThree &&  <Button onClick={()=> actionThree(textBox) } {...actionThreeProps} ml={3} >{textActionThree}</Button> }
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
};

export default EATextBoxDialog;
