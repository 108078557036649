import { FormControl, FormLabel, Input as ChakraInput, InputProps as ChakraInputProps } from '@chakra-ui/react';
import React, { forwardRef, ForwardRefRenderFunction } from 'react';

interface InputProps extends ChakraInputProps {
  name: string;
  placeholder?: string;
  label?: string;
  value?: any;
}

const InputBase: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { name, placeholder, label, value, ...rest },
  ref,
) => {
  return (
    <FormControl>
      {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}
      <ChakraInput
        ref={ref}
        id={name}
        name={name}
        value={value}
        placeholder={placeholder}
        variant="filled"
        size="lg"
        bg="gray.900"
        _hover={{
          bgColor: 'gray.900',
        }}
        focusBorderColor="green.500"
        color="gray.100"
        {...rest}
      />
    </FormControl>
  );
};

export default forwardRef(InputBase);
