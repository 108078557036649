import React from 'react';
import ReactDOM from 'react-dom';
import { ChakraProvider } from '@chakra-ui/react';

import App from './App';
import AppProvider from './hooks';
import { theme } from './styles/theme';
import './utils/icons';
import FirebaseHelper from './utils/FirebaseHelper';

FirebaseHelper.initialize()

ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <AppProvider>
        <App />
      </AppProvider>
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
