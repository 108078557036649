export const CEP = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 9;
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/^(\d{5})(\d)/, '$1-$2');
  e.currentTarget.value = value;
  return e;
};

export const CURRENCY = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d)(\d{2})$/, '$1,$2');
  value = value.replace(/(?=(\d{3})+(\D))\B/g, '.');

  e.currentTarget.value = value;
  return e;
};

export const CPF = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 14;
  let value = e.currentTarget.value;
  if (
    !value.match(/^(\d{3}).(\d{3}).(\d{3})-(\d{2})$/) &&
    value.length < e.currentTarget.maxLength
  ) {
    value = value.replace(/\D/g, '');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d)/, '$1.$2');
    value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
    // if (value.length === 14) {
    //   console.log(validateCPF(e.currentTarget.value))
    // }
  }
  return e;
};

export const CNPJ = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value;
  if (
    !value.match(/^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})$/) &&
    value.length < e.currentTarget.maxLength
  ) {
    value = value.replace(/\D/g, ''); // remove non number caracter
    value = value.replace(/(\d{2})(\d)/, '$1.$2'); // add dot to 3 position
    value = value.replace(/(\d{3})(\d)/, '$1.$2'); // add
    value = value.replace(/(\d{3})(\d)/, '$1/$2');
    value = value.replace(/(\d{4})(\d{2})$/, '$1-$2');
    e.currentTarget.value = value;
    // if (value.length === 18) {
    //   console.log(validateCPF(e.currentTarget.value))
    // }
  }
  return e;
};

export const EMAIL = (e: React.FormEvent<HTMLInputElement>) => {
  let value = e.currentTarget.value;
  if (
    !value.match(
      /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
    )
  ) {
    e.currentTarget.value = value;
    // if (value.length === 18) {
    //   console.log(validateCPF(e.currentTarget.value))
    // }
  }
  return e;
};

export const CPFCNPJ = (e: React.FormEvent<HTMLInputElement>) => {
  e.currentTarget.maxLength = 18;
  let value = e.currentTarget.value.replace(/\s+/g, '').replace(/\D/g, '');
  if (
    !value.match(
      /^(\d{2}).(\d{3}).(\d{3})\/(\d{4})-(\d{2})|(\d{3}).(\d{3}).(\d{3})-(\d{2})$/,
    )
  ) {
    if (value.replace(/\s+/g, '').replace(/\D/g, '').length <= 11) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d{2})$/, '$1-$2');
    }
    if (
      value.replace(/\s+/g, '').replace(/\D/g, '').length > 11 &&
      value.replace(/\s+/g, '').replace(/\D/g, '').length <= 14
    ) {
      value = value.replace(/\D/g, '');
      value = value.replace(/(\d{2})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1.$2');
      value = value.replace(/(\d{3})(\d)/, '$1/$2');
      value = value.replace(/(\d{4})(\d{2})$/, '$1-$2');
    }
    e.currentTarget.value = value;
  }
  return e;
};
