import { useDisclosure } from '@chakra-ui/hooks';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/modal';
import React, { useEffect } from 'react';

interface EAModalVideoPlayerProps  {
  videoUrl?: string;
  videoMimetype?: string;
  title?: string
  onCloseForm?: (reload: boolean, newData: any) => void;
}
const EAModalVideoPlayer: React.FC<EAModalVideoPlayerProps> = (props: EAModalVideoPlayerProps) => {
  const { 
    title,
    videoUrl, 
    videoMimetype,
    onCloseForm
  } = props  
  const { isOpen, onOpen, onClose } = useDisclosure();
  
   
  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };
  useEffect(() => {
    if (videoUrl && !isOpen){
      onOpen();
    } else if ( !videoUrl && isOpen){
      onClose()
    }
  }, [videoUrl]);
  return (<Modal
    closeOnOverlayClick={false}
    isOpen={isOpen}
    onClose={closeForm}
    size="xl" >
    <ModalOverlay />
    <ModalContent height="100vh" width="100vh">
      <ModalHeader>{ title }</ModalHeader>
      <ModalCloseButton />
      <ModalBody pb={2} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>    
        <video 
          id="videoPlayer" 
          controls
          autoPlay
          controlsList="nodownload"
        >
          <source src={ videoUrl } type={videoMimetype} />
        </video>
      </ModalBody>      
    </ModalContent>
  </Modal>);
};

export default EAModalVideoPlayer;
