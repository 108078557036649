import {
  chakra,
  HStack,
  IconButton,
  Input,
  Progress,
  Spacer,
  Stack,
  Table,
  TableCellProps,
  Tbody,
  Td,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import React, { useEffect, useMemo, useState } from 'react';

import {
  faEdit,
  faEye,
  faPlus,
  faSortDown,
  faSortUp,
  faSyncAlt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import iconFalse from '../../assets/false.png';
import iconTrue from '../../assets/true.png';
import AppUtil from '../../utils/AppUtil';
import { convertMaskPercent } from '../../utils/mask';
import EAConfirmationDialog from '../EAConfirmationDialog';
import PaginationComponent, { PaginationProps } from '../Pagination';

export type ColumnHeaders = {
  text: string;
  value: any;
  type?:
    | 'string'
    | 'integer'
    | 'float'
    | 'percent'
    | 'currency'
    | 'date'
    | 'time'
    | 'date'
    | 'datetime'
    | 'boolean'
    | 'action';
  align?: 'start' | 'center' | 'end';
  alignHeader?: 'start' | 'center' | 'end';
  sortable?: boolean;
  class?: TableCellProps;
  cellClass?: TableCellProps;
  width?: string | number;
  sort?: (a: any, b: any) => number;
};

export type DataTableProps = {
  isLoading: boolean;
  itens: any[];
  headers: ColumnHeaders[];
  isFiltered?: boolean;
  filter?: string;
  rowClick?: () => void;
  newItem?: () => void;
  itemActionEdit?: (item: any) => void;
  itemActionDelete?: (item: any) => void;
  itemActionView?: (item: any) => void;
  rowDoubleClick?: (item: any) => void;
  tableSync?: (
    filter: string,
    sort: string,
    page: number,
    skip: number,
    limit: number,
  ) => void;
  paginationOptions?: PaginationProps;
  columnSortable?: string;
};

export function getRowsPerPage(): number {
  const { innerHeight } = window;
  const header = (innerHeight * 7) / 100 + 110;
  const freeHeight = innerHeight - header;
  const rows = Math.floor(freeHeight / 47.5);
  // console.log(rows);
  return rows;
}

const DataTableComponent = (props: DataTableProps) => {
  const {
    itens,
    headers,
    filter,
    newItem,
    itemActionEdit,
    itemActionDelete,
    itemActionView,
    rowDoubleClick,
    tableSync,
    paginationOptions,
    columnSortable,
    isLoading,
  } = props;
  const data: Array<any> = useMemo(() => itens || [], [itens]);
  const columns: ColumnHeaders[] = useMemo(() => headers || [], [headers]);
  const [filtering, setFiltering] = useState<string>(filter || '');
  const [columnSort, setColumnSort] = useState<string>(columnSortable || '');
  const [page, setPage] = useState<number>(paginationOptions?.page || 1);
  const [skip, setSkip] = useState<number>(0);
  const [limit, setLimit] = useState<number>(
    paginationOptions?.itemsPerPage || 5,
  );
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(
    false,
  );
  const [deletingItem, setDeletingItem] = useState(null);
  const [isFiltering, setIsFiltering] = useState(false);
  const [execTableSync, setExecTableSync] = useState(false);

  // console.log('innerHeight: ', innerHeight)
  // console.log('innerWidth: ', innerWidth)
  useEffect(() => {
    if (deletingItem) {
      setIsConfirmationDialogOpen(true);
    } else {
      setIsConfirmationDialogOpen(false);
    }
  }, [deletingItem]);
  useEffect(() => {
    columns.push({ text: 'Action', value: 'action', type: 'action' });
  }, [headers]);

  function sortHandler(column: string) {
    if (columnSort && columnSort.startsWith('-')) {
      setColumnSort(column);
      if (tableSync) {
        tableSync(filtering, column, page, skip, limit);
      }
    } else {
      setColumnSort(`-${column}`);
      if (tableSync) {
        tableSync(filtering, `-${column}`, page, skip, limit);
      }
    }
  }
  // function sortHandler(column: string) {
  //   if (columnSort && columnSort.startsWith('-')) {
  //     setColumnSort(column);
  //     if (tableSync) {
  //       tableSync(filtering, column, page, skip, limit);
  //     }
  //   } else {
  //     setColumnSort(`-${column}`);
  //     if (tableSync) {
  //       tableSync(filtering, `-${column}`, page, skip, limit);
  //     }
  //   }
  // }

  useEffect(() => {
    if (execTableSync) {
      if (tableSync) {
        setPage(1);
        setSkip(0);
        tableSync(filtering, columnSort, 1, 0, limit);
      }
      setExecTableSync(false);
    }
  }, [execTableSync]);
  const filterHandler = (_filter: string) => {
    if (!isFiltering) {
      setIsFiltering(true);
      setTimeout(function () {
        setExecTableSync(true);
        setIsFiltering(false);
      }, 500);
    }
    setFiltering(_filter);
  };
  const actionPageGo = async (pPage: number, pSkip: number, pLimit: number) => {
    setPage(pPage);
    setSkip(pSkip);
    setLimit(pLimit);
    if (tableSync) {
      tableSync(filtering, columnSort, pPage, pSkip, pLimit);
    }
  };

  // const actionDelete = async (item) => {
  //   if (itemActionDelete) {
  //     itemActionDelete(item);
  //   }
  //   onClose();
  // };

  const hederSortable = () => {
    return columnSort.startsWith('-') ? (
      <FontAwesomeIcon icon={faSortDown} aria-label="sorted descending" />
    ) : (
      <FontAwesomeIcon icon={faSortUp} aria-label="sorted ascending" />
    );
  };

  // const handleOpenAlert = (item) => {
  //   console.log(item);
  //   setItemSelect(item);
  //   onOpen();
  // };

  const renderCell = (column: ColumnHeaders, item: any) => {
    const tdProps: TableCellProps = {
      p: '3',
      textAlign: column.align || 'start',
      ...column.cellClass,
    };

    if (column.type === 'integer') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
          isNumeric
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'percent') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
          isNumeric
        >
          {convertMaskPercent(item[column.value])}
        </Td>
      );
    }
    if (column.type === 'float') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
          isNumeric
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'currency') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
          isNumeric
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'date') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'time') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'datetime') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
        >
          {item[column.value]}
        </Td>
      );
    }
    if (column.type === 'boolean') {
      return (
        <Td
          {...tdProps}
          onClick={() => itemActionView && itemActionView(item)}
          key={`${column.value}-${item[column.value]}`}
        >
          {item[column.value] ? (
            <img style={{ display: 'inline' }} alt="Sim" src={iconTrue} />
          ) : (
            <img style={{ display: 'inline' }} alt="Não" src={iconFalse} />
          )}
        </Td>
      );
    }
    if (column.type === 'action') {
      return (
        (itemActionView || itemActionEdit || itemActionDelete) && (
          <Td
            p="0"
            // width="10%"
            width={`${2 * countActions()}%`}
            /*onClick={() => itemActionEdit && itemActionEdit(item)}*/
            key={'ItemActions'}
          >
            <HStack alignItems="center" justifyContent="space-around">
              {itemActionView && (
                <IconButton
                  key="itemActionView"
                  aria-label="View"
                  size="sm"
                  variant="outline"
                  color="blue.500"
                  onClick={() => itemActionView && itemActionView(item)}
                  icon={<FontAwesomeIcon icon={faEye} />}
                />
              )}
              {itemActionEdit && (
                <IconButton
                  key="itemActionEdit"
                  aria-label="Edit"
                  size="sm"
                  variant="outline"
                  color="blue.500"
                  onClick={() => itemActionEdit && itemActionEdit(item)}
                  icon={<FontAwesomeIcon icon={faEdit} />}
                />
              )}
              {itemActionDelete && (
                <>
                  <EAConfirmationDialog
                    isOpenDialog={isConfirmationDialogOpen}
                    textHeader="Exclusão!"
                    textBoby={`Confirma a exclusão do registro?`}
                    textActionOne="Sim"
                    actionOneProps={{
                      colorScheme: 'red',
                      onClick: () => {
                        itemActionDelete && itemActionDelete(deletingItem);
                        setDeletingItem(null);
                      },
                    }}
                    textActionTwo="Não"
                    actionTwoProps={{
                      onClick: () => {
                        setDeletingItem(null);
                      },
                    }}
                  />
                  <IconButton
                    key="itemActionDelete"
                    aria-label="Delete"
                    size="sm"
                    variant="outline"
                    color="red.500"
                    onClick={() => setDeletingItem(item)}
                    icon={<FontAwesomeIcon icon={faTrash} />}
                  />
                </>
              )}
            </HStack>
          </Td>
        )
      );
    }
    return (
      <Td {...tdProps} key={`${column.value}-${item[column.value]}`}>
        {item[column.value]}
      </Td>
    );
  };

  const countActions = (): number => {
    let count = 0;
    if (itemActionView) {
      count += 1;
    }
    if (itemActionEdit) {
      count += 1;
    }
    if (itemActionDelete) {
      count += 1;
    }
    return count;
  };

  // const popover = () => (
  //   <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose} placement="auto" closeOnBlur={false}>
  //     <PopoverTrigger>
  //       <IconButton
  //         aria-label="Delete"
  //         size="sm"
  //         variant="outline"
  //         color="red.500"
  //         icon={<FontAwesomeIcon icon={faTrash} />}
  //       />
  //     </PopoverTrigger>
  //     <PopoverContent border="1">
  //       <PopoverHeader fontWeight="semibold" d="flex" alignContent="center">
  //         Confirmation
  //       </PopoverHeader>
  //       <PopoverArrow />
  //       <PopoverBody fontWeight="normal">Confirmar exclusão?</PopoverBody>
  //       <PopoverFooter d="flex" justifyContent="space-between">
  //         <ButtonGroup size="sm" d="flex" justifyContent="space-between">
  //           <Button colorScheme="red" onClick={() => console.log('delete')}>
  //             Confirmar
  //           </Button>
  //           <Button colorScheme="red" variant="outline" onClick={onClose}>
  //             Cancelar
  //           </Button>
  //         </ButtonGroup>
  //       </PopoverFooter>
  //     </PopoverContent>
  //   </Popover>
  // );

  return (
    <>
      <Stack d="flex" w="100%">
        <HStack>
          <Input
            placeholder="Pesquisa"
            w="60%"
            value={filtering}
            onChange={(event) => {
              filterHandler(event.target.value);
            }}
          />

          <Tooltip label="Refresh data" aria-label="A tooltip">
            <IconButton
              aria-label="Next"
              size="sm"
              colorScheme="green"
              onClick={() => filterHandler(filtering)}
              icon={<FontAwesomeIcon icon={faSyncAlt} />}
            />
          </Tooltip>
          <Spacer />
          {paginationOptions && (
            <PaginationComponent
              alignSelf="self-end"
              itemsPerPage={paginationOptions?.itemsPerPage}
              allItems={paginationOptions?.allItems}
              page={page || paginationOptions?.page}
              pageChange={actionPageGo}
            />
          )}
          {/* {newItem && (
            <IconButton
              aria-label="Add"
              size="sm"
              color="green"
              variant="outline"
              onClick={() => newItem && newItem()}
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
          )} */}
        </HStack>
        {isLoading ? <Progress isIndeterminate w="100%" /> : null}
        <Table
          variant="striped"
          colorScheme="gray"
          border="0px"
          cellSpacing="1px"
        >
          <Thead>
            <Tr key={'lineHead'}>
              {columns?.map((column, idx) =>
                column.type !== 'action' ? (
                  <Th
                    textAlign={column.alignHeader || column.align || 'start'}
                    borderTopLeftRadius={idx === 0 ? '6px' : ''}
                    borderTopRightRadius={
                      idx === columns.length - 1 && countActions() === 0
                        ? '6px'
                        : ''
                    }
                    border="1px"
                    p="3"
                    key={`${column.value as string}-${AppUtil.randomId()}`}
                    backgroundColor={'gray.800'}
                    color="#FFFFFF"
                    onClick={() => {
                      sortHandler(column.value as string);
                    }}
                    {...column.class}
                  >
                    {column.text}
                    {column.sortable &&
                    columnSort.includes(column.value as string) ? (
                      <chakra.span pl="2">{hederSortable()}</chakra.span>
                    ) : null}
                  </Th>
                ) : (
                  (itemActionEdit || itemActionDelete || itemActionView) && (
                    <Th
                      textAlign="center"
                      border="1px"
                      backgroundColor={'gray.800'}
                      borderTopRightRadius="6px"
                      p="0"
                      color="#FFFFFF"
                      width={`${2 * countActions()}%`}
                      key={`${column.value as string}-${AppUtil.randomId()}`}
                    >
                      {newItem && (
                        <IconButton
                          aria-label="Add"
                          size="sm"
                          color="#FFFFFF"
                          backgroundColor="green"
                          onClick={() => newItem && newItem()}
                          icon={<FontAwesomeIcon icon={faPlus} />}
                        />
                      )}
                    </Th>
                  )
                ),
              )}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((item: any) => (
              <Tr
                p="3"
                key={item._id || AppUtil.randomId()}
                onDoubleClick={() => rowDoubleClick && rowDoubleClick(item)}
              >
                {columns?.map((column) => renderCell(column, item))}
              </Tr>
            ))}
          </Tbody>
        </Table>

        {/*
          paginationOptions ? (
          <PaginationComponent
            alignSelf="self-end"
            itemsPerPage={paginationOptions?.itemsPerPage}
            allItems={paginationOptions?.allItems}
            page={page || paginationOptions?.page}
            pageChange={actionPageGo}
          />
        ) : null
            */}
      </Stack>
    </>
  );
};

export default DataTableComponent;
