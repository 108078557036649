import {
  Button,
  Collapse,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
  VStack
} from '@chakra-ui/react';
import { faChevronDown, faChevronUp, faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import EAConfirmationDialog from '../../components/EAConfirmationDialog';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputDownload from '../../components/Form/EAInputDownload';
import EAInputUpload, { InputFile } from '../../components/Form/EAInputUpload';
import EATextarea from '../../components/Form/EATextarea';
import { INewsManagement } from '../../dtos/NewsManagement';
import api from '../../services/api';
import AppUtil from '../../utils/AppUtil';


type NewsManagementFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: INewsManagement) => void;
};
export interface IPayloadSend {
  id?: string;
  title: string;
  text: string;
}

const NewsManagementModal = ({ dataId, action, onCloseForm }: NewsManagementFormProps) => {
  const Toast = useToast();
  const [objectData, setObjectData] = useState<INewsManagement>({} as INewsManagement);
  const [inputFiles, setInputFiles] = useState<InputFile[]>([]);
  const [typeSend, setTypeSend] = useState<'APP'|'WEB'>('APP');
  const [payloadSend, setPayloadSend] = useState<IPayloadSend>();
  const [textConfirmation, setTextConfirmation] = useState<string>();
  const [collapseAttachment, setCollapseAttachment] = useState(false);
  const formRef = useRef<FormHandles>(null);
  // const [, setLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);

  const yupSchema = {
    title: Yup.string().required('Titulo é obrigatório!'),
    text: Yup.string().required('Mensagem é obrigatória!')
  }

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    // setLoad(true);
    const response: AxiosResponse = await api.get(`/news-management/get/${id}`);
    if (response && response.data) {
      const doc: INewsManagement = response.data
      if (doc && doc.attachments && doc.attachments.length > 0){
        setInputFiles(doc.attachments.map(doc => ({
          fileId: doc.attachmentId,
          fileName: doc.fileName
        })));
      }
      setTypeSend( doc.type === 'APP' ? "APP": "WEB")
      setObjectData(doc);
    }
  };

  const actionDownload = useCallback(async (e: InputFile) => {
    setIsDownloading(true)
    setDownloadingProgress(0)
    try {
      const responseDownloader: AxiosResponse = await api.get(`/news-management/download/${e.fileId}`, {
        responseType: 'blob',
        onDownloadProgress: (event) => {
          onProgressDownload(event)
      } });
      setDownloadingProgress(100)
      const { data, headers } = responseDownloader
      const contentType = headers['content-type']
      const contentName = headers['content-name']
      fileDownload(data, contentName, contentType)
      setIsDownloading(false)
      setDownloadingProgress(0)
    } catch (error) {
      setIsDownloading(false)
      setDownloadingProgress(0)
      Toast({
        status: 'error',
        title: 'Erro ao baixar arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);

  const actionDelete = async (e: InputFile, idx: number) => {
    if (!e.fileId){
      setInputFiles(val=> val.filter((_input, i)=> i!== idx))
      return;
    }
    try {
      setIsDeleting(true);
      const response: AxiosResponse = await api.delete(`/news-management/${dataId}/attachment/${e.fileId}`);
       Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Arquivo excluído com sucesso!',
        position: 'top-right',
      });
      setIsDeleting(false);
      setReload(true)      
      setInputFiles(val=> val.filter((_input, i)=> i!== idx))
    } catch (error) {
      setIsDeleting(false);
      Toast({
        status: 'error',
        title: 'Erro ao excluir arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }

  const actionInput = (e: InputFile, idx: number) => {
    inputFiles[idx] = e
    setInputFiles(inputFiles)
  };

  const onProgressUpload = useCallback((progressEvent) => {
    const percentFinal = Math.ceil(((progressEvent.total / 100)*3))
    setUploadingProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total + percentFinal)))
  }, []);

  const onProgressDownload = useCallback((progressEvent) => {
    setDownloadingProgress(Math.round( (progressEvent.loaded * 100) / (progressEvent.total) ))
  }, []);

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);
  const send = async () => {
    try {
      let savedId: string;
      setIsSaving(true);
      if (typeSend === 'APP'){
        const { data } = await api.post<INewsManagement>('/news-management/mobile/send',{...payloadSend, id: dataId});
        savedId = data?._id
      } else {
        const { data } = await api.post<INewsManagement>('/news-management/web/send',{...payloadSend, id: dataId});
        savedId = data?._id
      }
      const uploadFiles = inputFiles.filter(f => !f.fileId && f.file)
      if (savedId && uploadFiles.length > 0 ){
        setIsUploading(true)
        setUploadingProgress(0);        
        const formData = new FormData();
        let inc = 0;
        for (const file of uploadFiles) {
          if (file?.file){
            inc++
            formData.append(`file${inc}`, file?.file);
          }
        }        
        const savedFile = await api.post(`/news-management/${savedId}/upload`, formData, {
          onUploadProgress: (event) => {
            onProgressUpload(event)
        } });
        setUploadingProgress(98);
        setUploadingProgress(99);
        setUploadingProgress(100);
        setIsUploading(false)
      }

      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Notícia enviada com sucesso!',
        position: 'top-right',
      });
      closeForm(true, objectData);
    } catch (err) {
      setIsSaving(false)
      setUploadingProgress(0);
      setIsUploading(false)
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    } finally {      
      setIsSaving(false);
    }
  }
  const submit = async (data) => {
    const {title, text} = data
    try {
      let textAlert = ''
      if (typeSend === 'APP'){
          textAlert = `Enviar notificação para todos os cooperados?`
      } else {
          textAlert = `Enviar notificação para todos os colaboradores?`
      }
      setPayloadSend({
        title,
        text
      })
      setTextConfirmation(textAlert)
    } catch (err) {
      setIsSaving(false);
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };

  const setInputValue = async (value: any, field: string) => {
    objectData[field] = value
    setObjectData(objectData)
  }

  const FormNewsSection = () => {
    return (
      <>
        <Stack spacing="4" >   
              {
              /*  Desabilitado temporariamente, pois não há necessidade no momento de enviar Noticias para colaboradores.       
          <FormControl>
            <FormLabel htmlFor={"typeSend"} size="xs">Destino da Notícia</FormLabel>                   
            <HStack>
              <RadioGroup id='typeSend' name='typeSend' 
                value={typeSend} 
                onChange={(value) => {
                if (value == 'APP'){
                  setTypeSend('APP')
                } else {
                  setTypeSend('WEB')}
              }}>
                <Stack direction='row'>
                  <Radio disabled={!!dataId} value='APP'>Cooperados</Radio>
                  <Radio disabled={!!dataId} value='WEB'>Colaboradores</Radio>
                </Stack>
              </RadioGroup>
              
            </HStack>
          </FormControl>*/
              }
          <EAInput
            name="title"
            label="Titulo"
            isRequired
            isReadOnly={action === 'VIEW'}
            defaultValue={ objectData?.title || ''}
            onChange={($event)=> setInputValue($event.target.value, 'title')}
            autoComplete="none"
          />
          <EATextarea
            name="text"
            label="Mensagem"
            isRequired
            isReadOnly={action === 'VIEW'}
            defaultValue={ objectData?.message || ''}
            onChange={($event)=> setInputValue($event.target.value, 'message')}
            autoComplete="none"
          />
          <HStack>
            <Text pl="4" color={'red'}>*Atenção:</Text><Text>As notícias serão enviadas para todos os usuários ativos!</Text>     
          </HStack>
        </Stack>
      </>
    );
  }

  
  const ActionCollpase = ({collapse = true, onClick=(value: boolean) => {} } ) => {
    return collapse ? (
      <FontAwesomeIcon icon={faChevronDown} aria-label="expand" onClick={()=> onClick(!collapse)} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} aria-label="collapse" onClick={()=> onClick(!collapse)} />
    );
  };
  const SectionAttachments = () => {
    return (
      <>
        <VStack p={2} spacing={2} align={'flex-start'}>                
          <HStack w={'100%'} pr={4} >  
            <ActionCollpase collapse={collapseAttachment} onClick={() => setCollapseAttachment(!collapseAttachment)} />
            <Tooltip label={collapseAttachment ? 'Mostrar': 'Ocultar'}>
              <Heading size="md" onClick={() => setCollapseAttachment(!collapseAttachment)}>{'Anexos: '}</Heading>
            </Tooltip>
            <IconButton
              aria-label="Add"
              size="sm"
              color="#FFFFFF"
              backgroundColor="green"
              onClick={() => {
                setCollapseAttachment(false)
                setInputFiles([{}, ...inputFiles])
              }}
              icon={<FontAwesomeIcon icon={faPlus} />}
            />
          </HStack >  
            <Collapse in={!collapseAttachment} style={{width: '100%'}}>
              <VStack spacing={1}>
                {(inputFiles || []).map((a, index) => (
                  a.fileId ?
                  <EAInputDownload
                    key={'File_'+index+'_'+AppUtil.randomId()}
                    name={'File_'+index+'_'+AppUtil.randomId()}
                    fileActionDelete={(file: InputFile)=> actionDelete(file, index)}
                    fileActionDownload={actionDownload}
                    deleting={isDeleting}
                    downloding={isDownloading}
                    downlodingProgress={downloadingProgress}
                    isReadOnly={action === 'VIEW'}
                    file={a}
                  />
                  :                                
                  <EAInputUpload
                    key={'File_'+index+'_'+AppUtil.randomId()}
                    name={'File_'+index+'_'+AppUtil.randomId()}
                    type='file'
                    label="Anexo"
                    accept="application/pdf, .pdf"
                    fileActionInput={(file: InputFile)=> actionInput(file, index)}
                    isReadOnly={action === 'VIEW'}
                    uploading={inputFiles[index].fileName ? isUploading : false}
                    uploadingProgress={inputFiles[index].fileName ? uploadingProgress: 0}
                    file={inputFiles[index]}
                    fileActionDelete={(file: InputFile)=> actionDelete(file, index)}
                  />
                ))
              }
            </VStack>
          </Collapse>
        </VStack>
      </>
    );
  }
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader >
          {action === 'VIEW'
            ? 'Visualizar Notícia'
            : dataId
            ? 'Atualizar Notícia'
            : 'Nova Notícia'}
        </ModalHeader>
        <ModalBody pb={8} overflowX="auto">
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={yupSchema}>            
            <FormNewsSection/>
            <SectionAttachments/>
            <EAConfirmationDialog
                isOpenDialog={!!textConfirmation}
                textHeader="Atenção!"
                textBoby={textConfirmation || ''}
                textActionOne="Sim"
                actionOneProps={{
                  colorScheme:"green",
                  onClick: () => {
                    setTextConfirmation(undefined)
                    send();
                  }
                }}
                textActionTwo="Não"
                actionTwoProps={{
                  onClick: () => { 
                    setTextConfirmation(undefined)
                  }
                }}
              />
          </EAForm>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW' || isDeleting || isSaving}
            isLoading={isSaving}
            loadingText="Saving..."
            colorScheme="green"
            mr={3}
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={()=>formRef.current?.submitForm()}>
            Salvar
          </Button>
          <Button 
            isDisabled={ isDeleting || isSaving  }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(reload)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default NewsManagementModal;
