import { IMenu } from '../dtos/commons';

const menus: Array<IMenu> = [
  {
    title: 'Acessos',
    icon: 'people-arrows',
    order: 1,
    submenus: [
      {
        title: 'Usuário',
        icon: 'user',
        order: 1,
        action: '/register/user',
        permission: 'WEB:USER',
      },
      {
        title: 'Usuário Mobile',
        icon: 'user',
        order: 2,
        action: '/register/usermobile',
        permission: 'WEB:USERMOBILE',
      },
    ],
  },
  {
    title: 'Depósito',
    icon: 'boxes',
    order: 2,
    submenus: [
      {
        title: 'Parâmetros de Estoque',
        icon: 'cubes',
        order: 1,
        action: '/tools/parameters/stock',
        permission: 'WEB:STOCKWITHDRAWALPARAMS',
      },
      {
        title: 'Gestão de Entregas',
        icon: 'truck',
        order: 2,
        action: '/delivery-order',
        permission: 'WEB:COMMERCIAL',
      },
      {
        title: 'Painel de Carregamento',
        icon: 'truck-loading',
        order: 3,
        action: '/dashboard',
        permission: 'WEB:DELIVERYPANEL',
      },
    ],
  },
  {
    title: 'Notificações',
    icon: 'poll',
    order: 3,
    submenus: [
      {
        title: 'Resultado Pesquisa',
        icon: 'vial',
        order: 1,
        action: '/assay/result',
        permission: 'WEB:ASSAYRESULT',
      },
      {
        title: 'Notícias',
        icon: 'rss-square',
        order: 2,
        action: '/tools/news-management',
        permission: 'WEB:NEWS-MANAGEMENT',
      },
      {
        title: 'Notificações',
        icon: 'paper-plane',
        order: 3,
        action: '/tools/notification-send',
        permission: 'WEB:NOTIFICATION-MANAGEMENT',
      },
    ],
  },
  {
    title: 'Configurações',
    icon: 'tools',
    order: 4,
    submenus: [
      {
        title: 'Eventos Mobile',
        icon: 'mobile-alt',
        order: 4,
        action: '/tools/eventmobile',
        permission: 'WEB:EVENTMOBILE',
      },
      {
        title: 'Auditoria',
        icon: 'user-secret',
        order: 3,
        action: '/tools/audit',
        permission: 'WEB:AUDIT',
      },      
      {
        title: 'Parametros de Gerais',
        icon: 'cogs',
        order: 2,
        action: '/tools/parameters/general',
        permission: 'WEB:GENERALPARAMS',
      },
      {
        title: 'Config. Integração',
        icon: 'code-branch',
        order: 5,
        action: '/tools/integration',
        permission: 'WEB:INTEGRATION',
      },
      {
        title: 'Log Integração',
        icon: 'soap',
        order: 6,
        action: '/tools/integration-audit',
        permission: 'WEB:INTEGRATION-AUDIT',
      },
    ],
  } /*,
  {
    title: 'Perfil',
    icon: 'user',
    order: 3,
    action: '/session/profile',
  },*/,
];

export default menus;
