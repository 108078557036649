import axios, { AxiosResponse } from 'axios';
import AppConfig from '../config/AppConfig';

// const baseURL = 'https://api-license-control.mschueroff.com.br/',
const baseURL = String(AppConfig.hostApi).trim();
const api = axios.create({
  baseURL: `${baseURL.endsWith('/') ? baseURL.substr(0, baseURL.length - 1) : baseURL}/web/`,
});

api.interceptors.response.use(
  async (response: AxiosResponse<any>) => {
    return response;
  },
  (error) => {
    // console.error('api.interceptors.error.code-mesage: ', error.code, error.message);
    // console.error('api.interceptors.error.response: ', error.response);
    // console.error('api.interceptors.error: ', error);
    if (error.message === 'Network Error') {
      throw new Error(`Api Server "${baseURL}" not response!`);
    }
    if (error.code === 'ECONNREFUSED') {
      throw new Error(`Network Connect Timeout. Waiting for server connection: ${baseURL}`);
    }
    if (error.code === 'ENOTFOUND') {
      throw new Error(`Api Server "${baseURL}" not response!`);
    }

    if (error.code === 'ETIMEDOUT') {
      throw new Error(`Api Server "${baseURL}" timeout!`);
    }
    if (error.response && [400, 401].includes(error.response.status)) {
      throw new Error(error.response.data.message);
    }
    return Promise.reject(error);
  },
);

export default api;
