import { IAppConfigDTO } from '../dtos/commons';
// Configuração para rodar aplicação em um container docker com variaveis de ambiente.
// Gerar Docker Image => https://www.freecodecamp.org/news/how-to-implement-runtime-environment-variables-with-create-react-app-docker-and-nginx-7f9d42a91d70/
// https://github.com/kunokdev/cra-runtime-environment-variables

// *** User yarn dev, em terminal linux(Git bash ou WSL-linux subsystem) para carregar as variaveis de ambiente em "window.appConfig", ainda não temos funcionando para windows
const AppConfig: IAppConfigDTO = {
  hostApi: window.appConfig?.API_URL || 'https://api-ecooperative.mschueroff.com.br/',
  company: window.appConfig?.COMPANY || 'Engenho Agro Dev',
};

export default AppConfig;
