import {
  Button,
  FormControl,
  FormLabel,
  HStack,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';

import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import EAConfirmationDialog from '../../components/EAConfirmationDialog';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EASelect from '../../components/Form/EASelect';
import EATextarea from '../../components/Form/EATextarea';
import { IApiResponse } from '../../dtos/commons';
import { IUserMobile } from '../../dtos/user-mobile';
import api from '../../services/api';

export interface PermissionList {
  description: string;
  feature: string;
}
export interface IPayloadSend {
  user?: string | string[];
  title: string;
  text: string;
}
export interface IUserSend {
  value: string;
  label: string;
}

const NotificationSend = () => {
  const Toast = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const formRef = useRef<FormHandles>(null);
  const [typeSend, setTypeSend] = useState<'APP' | 'WEB'>('APP');
  const [selectedUser, setSelectedUser] = useState<IUserSend[]>([]);
  const [optionsUser, setOptionsUser] = useState<IUserSend[]>([]);
  const [payloadSend, setPayloadSend] = useState<IPayloadSend>();
  const [textConfirmation, setTextConfirmation] = useState<string>();

  const getOptionsUser = async (type) => {
    if (type === 'APP') {
      const { data } = await api.get<IUserMobile[]>('/usermobile?sort=name');
      return data
        ?.filter((f) => f.active)
        .map((r) => ({ label: r.name, value: r._id }));
    } else {
      const { data } = await api.get<IUserMobile[]>('/user?sort=name');
      return data
        ?.filter((f) => f.active)
        .map((r) => ({ label: r.name, value: r._id }));
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      const result = await getOptionsUser(typeSend);
      setOptionsUser(result);
    }
    getDataFromAPI();
  }, []);

  useEffect(() => {
    async function getDataFromAPI() {
      setSelectedUser([]);
      const result = await getOptionsUser(typeSend);
      setOptionsUser(result);
    }
    getDataFromAPI();
  }, [typeSend]);

  const onChange = (value, { action }) => {
    setSelectedUser(value);
  };
  const yupSchema = {
    title: Yup.string().required('Titulo é obrigatório!'),
    text: Yup.string().required('Mensagem é obrigatória!'),
  };
  const send = async () => {
    try {
      setIsSaving(true);
      if (typeSend === 'APP') {
        const { data } = await api.post<IApiResponse>(
          '/notification-management/mobile/send',
          payloadSend,
        );
        console.log('data app => ', data);
        if (data && data.code === 'success') {
          Toast({
            status: 'success',
            title: 'Sucesso',
            description: 'Notificação enviada',
            position: 'top-right',
          });
        } else {
          Toast({
            status: 'warning',
            title: data.code,
            description: data.message,
            position: 'top-right',
          });
        }
      } else {
        const { data } = await api.post<IApiResponse>(
          '/notification-management/web/send',
          payloadSend,
        );
        if (data && data.code === 'success') {
          Toast({
            status: 'success',
            title: 'Sucesso',
            description: 'Notificação enviada',
            position: 'top-right',
          });
        } else {
          Toast({
            status: 'warning',
            title: data.code,
            description: data.message,
            position: 'top-right',
          });
        }
      }
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    } finally {
      setIsSaving(false);
    }
  };
  const submit = async (data) => {
    const { title, text } = data;
    try {
      let textAlert = '';
      if (typeSend === 'APP') {
        if (selectedUser && selectedUser.length === 1) {
          textAlert = `Enviar notificação para o cooperado ${selectedUser[0].label}?`;
        } else if (selectedUser && selectedUser.length > 1) {
          textAlert = `Enviar notificação os ${selectedUser.length} cooperados selecionados?`;
        } else {
          textAlert = `Enviar notificação para todos os cooperados?`;
        }
      } else {
        if (selectedUser && selectedUser.length === 1) {
          textAlert = `Enviar notificação para o colaborador ${selectedUser[0].label}?`;
        } else if (selectedUser && selectedUser.length > 1) {
          textAlert = `Enviar notificação os ${selectedUser.length} colaboradores selecionados?`;
        } else {
          textAlert = `Enviar notificação para todos os colaboradores?`;
        }
      }
      setPayloadSend({
        user:
          selectedUser && selectedUser.length > 0
            ? selectedUser?.map((s) => s.value)
            : undefined,
        title,
        text,
      });
      setTextConfirmation(textAlert);
    } catch (err) {
      setIsSaving(false);
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };
  const handleSubmit = () => {
    formRef.current?.submitForm();
  };
  return (
    <>
      <Stack d="flex" w="100%">
        <EAForm formRef={formRef} onSubmit={submit} yupSchema={yupSchema}>
          <Stack spacing="4">
            <FormControl>
              <FormLabel htmlFor={'typeSend'} size="xs">
                Destino da Notificação
              </FormLabel>
              <HStack>
                <RadioGroup
                  id="typeSend"
                  name="typeSend"
                  value={typeSend}
                  onChange={(value) => {
                    if (value === 'APP') {
                      setTypeSend('APP');
                    } else {
                      setTypeSend('WEB');
                    }
                  }}
                >
                  <Stack direction="row">
                    <Radio value="APP">Cooperados</Radio>
                    <Radio value="WEB">Colaboradores</Radio>
                  </Stack>
                </RadioGroup>
              </HStack>
            </FormControl>
            <EAInput
              name="title"
              label="Titulo"
              isRequired
              defaultValue={''}
              autoComplete="none"
            />
            <EATextarea
              name="text"
              label="Mensagem"
              isRequired
              defaultValue={''}
              autoComplete="none"
            />
            <EASelect
              name="selectedUser"
              label="Usuarios"
              placeholder="Filtre pelo usuário"
              value={selectedUser}
              isClearable
              onChange={onChange}
              options={optionsUser}
              styles={{
                container: (styles) => ({ ...styles, flex: 1 }),
              }}
              isMulti
              className="basic-multi-select"
              classNamePrefix="select"
            />
            <HStack>
              <EAConfirmationDialog
                isOpenDialog={!!textConfirmation}
                textHeader="Atenção!"
                textBoby={textConfirmation || ''}
                textActionOne="Sim"
                actionOneProps={{
                  colorScheme: 'green',
                  onClick: () => {
                    setTextConfirmation(undefined);
                    send();
                  },
                }}
                textActionTwo="Não"
                actionTwoProps={{
                  onClick: () => {
                    setTextConfirmation(undefined);
                  },
                }}
              />
              <Button
                isDisabled={isSaving}
                isLoading={isSaving}
                loadingText="Saving..."
                colorScheme="blue"
                mr={3}
                onClick={handleSubmit}
              >
                Enviar
              </Button>
            </HStack>
          </Stack>
        </EAForm>
      </Stack>
    </>
  );
};

export default NotificationSend;
