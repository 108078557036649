/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import { IUserMobile } from '../../dtos/user-mobile';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import UserMobileModal from './UserMobileModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const UserMobile = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [userList, setUserList] = useState<IUserMobile[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage);
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string>();

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setUserList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/usermobile/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: IUserMobile[] = response.data.rows || []
        setUserList(docs.map((d: IUserMobile) => ({...d, login: d?.login?.replace(/[^\d]/g, "").length > 11 ? d.login?.replace(/[^\d]/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : d.login?.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")})));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Login', value: 'login', align: 'center', sortable: true },
      { text: 'Name', value: 'name', alignHeader: 'center', sortable: true },
      { text: 'Email', value: 'email', alignHeader: 'center', sortable: true },
      { text: 'Ativo', value: 'active', type: 'boolean', align: 'center', sortable: true },
    ]);
    getData({});
  }, []);

  return (
    <>
      {showModal ? <UserMobileModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={userList}
        filter={filterValue}
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        newItem={() => {
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionEdit={(item: IUserMobile) => {
          // console.log(item.name);
          setDataId(item._id);
          setShowModal(true);
          setActionModal('EDIT');
        }}
        itemActionView={(item: IUserMobile) => {
          setDataId(item._id);
          setActionModal('VIEW');
          setShowModal(true);
        }}
        // itemActionDelete={async (item: IUser) => {
        //   inactiveItem(item);
        // }}
        columnSortable={sortable}
      />
    </>
  );
};

export default UserMobile;
