const clearString = (value: string): string => {
  return value.replace(/\D/g, '');
}

export const CPF = (value: string): string => {
  return clearString(value).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "\$1.\$2.\$3\-\$4");
}

export const CNPJ = (value: string): string => {
  return clearString(value).replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
}

//regexs para converter e mascara.

export const convertMaskPercent = (value: any, precision = 2, precisionMax = 2) => {
  // return (parseInt(value.toString().replace(/[^0-9]/g, "")) * 0.01).toLocaleString("pt-BR", { style: "percent" });
  return (parseFloat(value)/100).toLocaleString("pt-BR", { style: "percent", minimumFractionDigits: precision, maximumFractionDigits: precisionMax });
};
