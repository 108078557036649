import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
`;

export const InternalContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 0.875rem;
`;

export const Section = styled.div`
  display: flex;
  width: auto;
  flex: 1;
  border: 0.5px solid #41414a;
`;
