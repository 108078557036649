/* eslint-disable no-param-reassign */
export default function addProps(obj, arr, val) {
  if (typeof arr === 'string') arr = arr.split('.');

  obj[arr[0]] = obj[arr[0]] || {};

  const tmpObj = obj[arr[0]];

  if (arr.length > 1) {
    arr.shift();
    addProps(tmpObj, arr, val);
  } else obj[arr[0]] = val;

  return obj;
}
