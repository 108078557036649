/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useToast } from '@chakra-ui/toast';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IAudit } from '../../dtos/audit';
import AuditModal from './AuditModal';
import { HStack, Input, Stack } from '@chakra-ui/react';
import DateUtil from '../../utils/DataUtil';
import moment from 'moment';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const Audit = () => {
  const Toast = useToast();
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [AuditList, setAuditList] = useState<IAudit[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSortable] = useState<string|undefined>('-dateOf');
  const [initDate, setInitialDate] = useState<string>(DateUtil.stringFormatt(DateUtil.addDays(new Date(), -15), 'YYYY-MM-DD'));
  const [finishDate, setFinishDate] = useState<string>(DateUtil.stringFormatt(new Date(), 'YYYY-MM-DD'));

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (initDate){
        query.push(`initialdate=${ moment(initDate).toJSON() }`);
      }
      if (finishDate){
        query.push(`finishdate=${ moment(finishDate).toJSON() }`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }
      
      query.push(`sort=${sort||'-dateOf'}`);

      setAuditList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/audit/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: IAudit[] = response.data.rows || []
        setAuditList(docs.map((a: IAudit)=> ({
          ...a,
          userAudit: a.user ? a.user.name : a.userMobile?.name
        })));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Entidade', value: 'entity', alignHeader: 'center', cellClass: {w: '10%'}, sortable: true },
      { text: 'Operação', value: 'operation', align: 'center', cellClass: {w: '7%'}, sortable: true },
      { text: 'Origem', value: 'source', align: 'center', cellClass: {w: '7%'}, sortable: true },
      { text: 'Usuário', value: 'userAudit', alignHeader: 'center', sortable: true },
      { text: 'Data', value: 'dateOf', align: 'center', type: 'datetime', cellClass: {w: '13%', minW: '45px'}, sortable: true },
      { text: 'Transação', value: 'transaction', alignHeader: 'center', cellClass: {w: '15%'}, sortable: true },
      { text: 'Mensagem', value: 'log', alignHeader: 'center', sortable: true }
    ]);
    getData({});
  }, []);
  
  useEffect(() => {
    getData({});
  }, [initDate, finishDate]);
  return (
    <> 
      {showModal ? <AuditModal dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <Stack d="flex" w="100%">
      <HStack>
        <Input
            placeholder="Data Inicial"
            type='date'
            maxW="200px"
            minW="180px"
            value={initDate}
            onChange={(event) => {
              setInitialDate(event.target.value);
            }}
          />
          <Input
            placeholder="Data Final"
            type='date'
            maxW="200px"
            minW="180px"
            value={finishDate}
            onChange={(event) => {              
              setFinishDate(event.target.value);              
            }}
          />
        </HStack>
        <DataTableComponent
          isLoading={isLoading}
          headers={headers}
          itens={AuditList}
          filter={filterValue}
          paginationOptions={{
            allItems: allRows,
            itemsPerPage: rowsPerPage,
            page: currentPage,
          }}
          tableSync={(filter, sort, page, offset, limit) => {
            getData({ filter, sort, page, offset, limit });
          }}
          itemActionView={(item: IAudit) => {
            setDataId(item._id);
            setActionModal('VIEW');
            setShowModal(true);
          }}
          /*
          rowDoubleClick={(item: IAudit) => {
            setDataId(item._id);
            setActionModal('VIEW');
            setShowModal(true);
          }}
          */
          columnSortable={sortable}
        />
      </Stack>
    </>
  );
};

export default Audit;
