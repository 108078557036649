export enum EDeliveryOrderStatus {
  WAITING_BILLING = 'Em faturamento',
  IN_SEPARATION = 'Em separação',
  WAITING_INVOICE = 'Aguardando emissão da nota',
  WAITING_RELEASE = 'Aguardando liberação',
  RELEASED = 'Liberado',
  CANCELED = 'Cancelado',
}

export interface DeliveryOrderBoardDTO {
  code?: string;
  producer: string;
  producerName: string;
  status: EDeliveryOrderStatus;
  dateOf: Date;
  createdAt: Date;
  dateStatus: Date;
  itens: number;
  quantity: number;
  priority: number;
}

export interface IAttachmentDeliveryOrder {
  fileName: string;
  mimetype: string;
  createdAt: Date;
  attachmentId: string;
}

export interface IProductDeliveryOrder {
  deliveryOrderId: string;
  productId: string;
  productName: string;
  crop: string;
  quantity: number;
  quantitySaved: number;
}

export interface IDeliveryOrder {
  key: string;
  deliveryCode: string;
  producerId: string;
  producerName: string;
  status: EDeliveryOrderStatus;
  dateOf: Date | string;
  createdAt: Date | string;
  dateStatus: Date;
  code: string;
  note: string;
  orderCode: Array<string>;
  invoiceCode: Array<string>;
  products: Array<IProductDeliveryOrder>;
  attachments: Array<IAttachmentDeliveryOrder>;
}
