
import { FirebaseApp, initializeApp } from "firebase/app"; 
import { Analytics, getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useToast } from "@chakra-ui/react";

export default class FirebaseHelper {
  private static app: FirebaseApp;
  private static analytics: Analytics;
  private static isInitialized = false;
  public static initialize (): void {
    const firebaseConfig = {
      apiKey: "AIzaSyAeUav6YFVAC2JSO2ndlRehJ2765cQs8hY",
      projectId: "e-cooperativa",
      messagingSenderId: "322598641926",
      appId: "1:322598641926:web:11ac6953632bf7c3073e87",
      measurementId: "G-M8QV37KRMR"
    };
    
    this.app = initializeApp(firebaseConfig)
    this.analytics = getAnalytics(this.app);
    this.isInitialized = true
  }
  

  public static async getMessagingToken (): Promise<string> {
    if (!this.isInitialized) {
      throw new Error('Firebase not initialized!')
    }
    console.log('getMessagingToken ==> ', this.app)
    let token : string = '';
    try {
      const permission = await Notification.requestPermission();
      console.log('requestPermission: ', permission)
      if (permission === 'granted') {
        const message = getMessaging(this.app)
        console.log('getMessagingToken.message ==> ', message)
        token = await getToken(message);
      }      
    } catch (error) {
      console.error('Error get messaging token. Error: ', error)
    }
    console.log('getMessagingToken => ', token)
    return token
  }
  

  public static listeningOnMessaging (): void {
    if (!this.isInitialized) {
      throw new Error('Firebase not initialized!')
    }
    
    const Toast = useToast();
    onMessage(getMessaging(this.app), (payload) => {
      const { notification, data } = payload
      console.log('Message received. ', payload);
      
      Toast({
        status: 'info',
        title: notification?.title,
        description: notification?.body,
        position: 'bottom-right'
      });
      // ...
    });
  }
}