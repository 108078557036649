import styled from 'styled-components';

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  min-width: 300px;
  width: 20%;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  background-color: #eee;
  height: 100%;
  overflow: auto;
`;

export const SectionHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 10px;
  font-weight: bold;
  font-size: 0.875rem;
`;

export const SectionBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  margin: 0;
  padding: 10px 10px 0;
  list-style: none;
`;
