import React, { createContext, useCallback, useState, useContext } from 'react';
import { ISession, ISignInCredentials } from '../dtos/user';
import api from '../services/api';
import Base64 from '../utils/Base64';
import FirebaseHelper from '../utils/FirebaseHelper';

interface AuthProviderProps {
  children: React.ReactNode;
}
interface AuthContextData {
  session: ISession;
  tokenSaved: string | null;
  signIn(credentials: ISignInCredentials): Promise<ISession>;
  signOut(): void;
  signInToken(): Promise<void>;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [data, setData] = useState<ISession>({} as ISession);
  const [tokenSaved] = useState(localStorage.getItem('@MyApp:token'));

  
  FirebaseHelper.listeningOnMessaging()

  const signIn = useCallback(async ({ login, password, token }: ISignInCredentials) => {
    if (token) {
      const messagingToken = await FirebaseHelper.getMessagingToken()
      api.defaults.headers.messagingToken = messagingToken;
      api.defaults.headers.authorization = `Bearer ${token}`;

      const response = await api.get('/session/user');

      const session: ISession = response.data;

      localStorage.setItem('@MyApp:token', session.token);

      api.defaults.headers.authorization = `Bearer ${session.token}`;

      setData(session);

      return session;
    }

    const response = await api.post('session/authentication', null, {
      headers: {
        authorization: `Basic ${Base64.encode(`${login}:${password}`)}`,
      },
    });

    const session: ISession = response.data;

    localStorage.setItem('@MyApp:token', session.token);

    api.defaults.headers.authorization = `Bearer ${session.token}`;

    setData(session);
    return session;
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@MyApp:token');
    delete api.defaults.headers.authorization;

    setData({} as ISession);
  }, []);

  const signInToken = async () => {
    try {
      const token = localStorage.getItem('@MyApp:token');
      if (token) {
        const messagingToken = await FirebaseHelper.getMessagingToken()
        api.defaults.headers.messagingToken = messagingToken;
        api.defaults.headers.authorization = `Bearer ${token}`;
        const response = await api.get('/session/user');
        const session: ISession = response.data;
        localStorage.setItem('@MyApp:token', session.token);
        api.defaults.headers.authorization = `Bearer ${session.token}`;
        setData(session);
      }      
    } catch (error) {
      // console.log('signInToken: ',error)
      signOut();
    }
  };

  return (
    <AuthContext.Provider value={{ session: data, tokenSaved, signIn, signOut, signInToken }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
