import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Heading,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { faPlus, faChevronDown, faChevronUp, faTrash, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import EAForm from '../../components/Form/EAForm';
import EAInputDownload from '../../components/Form/EAInputDownload';
import EAInputUpload, { InputFile } from '../../components/Form/EAInputUpload';
import LoadingBackdrop from '../../components/LoadingBackdrop';
import { EDeliveryOrderStatus, IDeliveryOrder, IProductDeliveryOrder } from '../../dtos/delivery-order';
import api from '../../services/api';
import * as Yup from 'yup';
import EAInput from '../../components/Form/EAInput';
import DateUtil from '../../utils/DataUtil';
import EASelect from '../../components/Form/EASelect';
import { ISelectOptions } from '../../components/Form/EASelect';
import EATextBoxDialog from '../../components/EATextBoxDialog';
import EATextarea from '../../components/Form/EATextarea';
import { ObjectShape } from 'yup/lib/object';
import AppUtil from '../../utils/AppUtil';

type DeliveryOrderFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IDeliveryOrder) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const DeliveryOrderModal = ({
  dataId,
  action,
  onCloseForm,
}: DeliveryOrderFormProps) => {
  const [objectData, setObjectData] = useState<IDeliveryOrder>(
    {} as IDeliveryOrder,
  );
  
  const yupSchema = {
    code: Yup.string(),
    producerName: Yup.string().required('Descrição é obrigatória!'),
    orderCode: Yup.string(),
    invoiceCode: Yup.string(),
    dateOf: Yup.string(),
    status: Yup.string(),
    dateStatus: Yup.string(),
    note: Yup.string()
  }

  const Toast = useToast();
  const formRef = useRef<FormHandles>(null);
  const [inputFiles, setInputFiles] = useState<InputFile[]>([]);
  const [collapseProducts, setCollapseProducts] = useState(false);
  const [collapseAttachment, setCollapseAttachment] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingProgress, setDownloadingProgress] = useState(0);
  const [canceledProducts, setCanceledProducts] = useState<IProductDeliveryOrder[]>([]);
  const [reload, setReload] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [optionsStatus, setOptionsStatus] = useState<ISelectOptions[]>([])
  const [showTextBox, setShowTextBox] = useState(false);
  const [originalStatus, setOriginalStatus] = useState<string>();
  const [formSchema, setFormSchema] = useState<ObjectShape>(yupSchema);

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse<IDeliveryOrder> = await api.get(
      `/delivery-order/detail/${id}`,
    );
    if (response && response.data) {
      const doc: IDeliveryOrder = response.data;
      const optStatus: ISelectOptions[] = []
      setOriginalStatus(doc.status);
      optStatus.push({ label: EDeliveryOrderStatus[doc.status], value: doc.status})
      if (doc.status.toString() === 'WAITING_RELEASE'){
        optStatus.push({ label: EDeliveryOrderStatus.RELEASED, value: 'RELEASED'})
        optStatus.push({ label: EDeliveryOrderStatus.CANCELED, value: 'CANCELED'})
      }
      if (doc.status.toString() === 'WAITING_BILLING'){
        optStatus.push({ label: EDeliveryOrderStatus.CANCELED, value: 'CANCELED'})
      }
      setOptionsStatus(optStatus)
      setObjectData({
        ...doc,
      });
      setInputFiles(doc.attachments.map(doc => ({
        fileId: doc.attachmentId,
        fileName: doc.fileName
      })));
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);
  
  useEffect(()=> {
    setFormSchema({...formSchema, note:(objectData.status as string) === 'CANCELED' ? Yup.string().required('Observação é obrigatória!'): Yup.string()})
  }, [objectData.status])
  
  const setInputValue = async (value: any, field: string) => {
    objectData[field] = value
    setObjectData(objectData)
  }
  const actionInput = (e: InputFile, idx: number) => {
    inputFiles[idx] = e
    setInputFiles(inputFiles)
  };

  const onProgressDownload = useCallback((progressEvent) => {
    setDownloadingProgress(
      Math.round((progressEvent.loaded * 100) / progressEvent.total),
    );
  }, []);
  
  const onProgressUpload = useCallback((progressEvent) => {
    const percentFinal = Math.ceil(((progressEvent.total / 100)*3))
    setUploadingProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total + percentFinal)))
  }, []);
  const actionDownload = useCallback(async (e: InputFile) => {
    setIsDownloading(true)
    setDownloadingProgress(0)
    try {
      const responseDownloader: AxiosResponse = await api.get(`/delivery-order/download/${e.fileId}`, {
        responseType: 'blob',
        onDownloadProgress: (event) => {
          onProgressDownload(event)
      } });
      setDownloadingProgress(100)
      const { data, headers } = responseDownloader
      const contentType = headers['content-type']
      const contentName = headers['content-name']
      fileDownload(data, contentName, contentType)
      setIsDownloading(false)
      setDownloadingProgress(0)
    } catch (error) {
      setIsDownloading(false)
      setDownloadingProgress(0)
      Toast({
        status: 'error',
        title: 'Erro ao baixar arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);

  const actionDelete = async (e: InputFile, idx: number) => {
    if (!e.fileId){
      setInputFiles(val=> val.filter((_input, i)=> i!== idx))
      return;
    }
    try {
      setIsDeleting(true);
      const response: AxiosResponse = await api.delete(`/delivery-order/${objectData.deliveryCode}/attachment/${e.fileId}`);
       Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Arquivo excluído com sucesso!',
        position: 'top-right',
      });
      setIsDeleting(false);
      setReload(true)      
      setInputFiles(val=> val.filter((_input, i)=> i!== idx))
    } catch (error) {
      setIsDeleting(false);
      Toast({
        status: 'error',
        title: 'Erro ao excluir arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }

  const submit = async (data: IDeliveryOrder) => {
    try {
      const { status, deliveryCode, note } = objectData
      //const { status } = data
      setIsSaving(true)
      const savingData = objectData.products.map(p => ({
        id: p.deliveryOrderId,
        status,
        note
      }))
      const saved = await api.post('/delivery-order/save',savingData);
      const uploadFiles = inputFiles.filter(f => !f.fileId && f.file)
      if (deliveryCode && uploadFiles.length > 0 ){
        setIsUploading(true)
        setUploadingProgress(0);
        
        const formData = new FormData();
        let inc = 0;
        for (const file of uploadFiles) {
          if (file?.file){
            inc++
            formData.append(`file${inc}`, file?.file);
          }
        }        
        const savedFile = await api.post(`/delivery-order/${deliveryCode}/upload`, formData, {
          onUploadProgress: (event) => {
            onProgressUpload(event)
        } });
        setUploadingProgress(98);
        setUploadingProgress(99);
        setUploadingProgress(100);
        setIsUploading(false)
      }

      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Registro atualizado com sucesso!',
        position: 'top-right',
      });
      setIsSaving(false)
      closeForm(true, objectData);
    } catch (err) {
      setIsSaving(false)
      setUploadingProgress(0);
      setIsUploading(false)
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };

  const cancelarItens = async (note: string) => {
    try {
      const allCanceled = canceledProducts.length === objectData.products.length
      if (canceledProducts.length <= 0){
        return;
      }
      if (!note){
        Toast({
          status: 'warning',
          title: 'Atenção!',
          description: 'A observação é obrigatório para cancelamento!',
          position: 'top-right',
        });
        return;
      }
      
      setShowTextBox(false) 
      setIsSaving(true)
      const savingData = canceledProducts.map(p => ({
        id: p.deliveryOrderId,
        status: 'CANCELED',
        note
      }))
      const saved = await api.post('/delivery-order/save',savingData);
      
      await getDocument(dataId as string);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Registro atualizado com sucesso!',
        position: 'top-right',
      });
      setIsSaving(false)
      if (allCanceled){        
        closeForm(true, objectData);
      } else {
        setShowTextBox(false) 
      }
    } catch (err) {
      setIsSaving(false)
      setShowTextBox(true) 
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };
  const ActionCollpase = ({collapse = true, onClick=(value: boolean) => {} } ) => {
    return collapse ? (
      <FontAwesomeIcon icon={faChevronDown} aria-label="expand" onClick={()=> onClick(!collapse)} />
    ) : (
      <FontAwesomeIcon icon={faChevronUp} aria-label="collapse" onClick={()=> onClick(!collapse)} />
    );
  };

  const SectionDelivey = () => {
    return (
    <>
      <HStack spacing={4}>       
        <EAInput
          name="code"
          label="Ordem"
          isReadOnly={true}
          defaultValue={ objectData?.code || 'Aguardando Geração ERP' }
          autoComplete="none"
        />          
        <EAInput
          name="dateOf"
          label="Data"
          isReadOnly={true}
          defaultValue={ DateUtil.stringFormatt(new Date(objectData?.dateOf), 'DD/MM/YYYY') }
          autoComplete="none"
        />
        <EASelect
          name="status"
          label="Status"
          isReadOnly={optionsStatus.length <= 1}
          value={{ label: EDeliveryOrderStatus[objectData.status], value: objectData.status} }
          //isClearable
          onChange={ item => setObjectData({...objectData, status:  item.value})}
          options={optionsStatus}
          styles={{
            container: (styles) => ({ ...styles, flex: 1}),
          }}
          //isMulti
          // className="basic-multi-select"
          classNamePrefix="select"
        />
      </HStack>
      <HStack spacing={4}>          
        <EAInput
          name="producerName"
          label="Cooperado"
          isReadOnly={true}
          defaultValue={ objectData?.producerName || ''}
          autoComplete="none"
        />
      </HStack>
      <HStack spacing={4}>        
        <EAInput
          name="orderCode"
          label="Pedido(s)"
          isReadOnly={true}
          defaultValue={ objectData?.orderCode?.join(', ') || ''}
          autoComplete="none"
        />        
        <EAInput
          name="invoiceCode"
          label="Nota(s)"
          isReadOnly={true}
          defaultValue={ objectData?.invoiceCode?.join(', ') || ''}
          autoComplete="none"
        />
      </HStack>
      <HStack spacing={4}>          
        <EATextarea
          name="note"
          label="Observação"
          isRequired={(objectData.status as string) === 'CANCELED'}
          isReadOnly={optionsStatus.length <= 1}
          defaultValue={ objectData?.note || ''}
          onChange={($event)=> setInputValue($event.target.value, 'note')}
          autoComplete="none"
        />
      </HStack>
      </>
    );
  }

  const isCheckedProduct = (item: IProductDeliveryOrder) => {
    return canceledProducts.map(c=>c.deliveryOrderId.toString()).includes(item.deliveryOrderId.toString())
  }
  const addCancelamento = (item: IProductDeliveryOrder, add: boolean) => {
    if (!item) {
      return;
    }
    if (add && !isCheckedProduct(item)){
      setCanceledProducts([...canceledProducts, item])
    } else {
      setCanceledProducts(val=> val.filter(f=>f.deliveryOrderId.toString() !== item.deliveryOrderId.toString()))
    }    
  }
  const permiteCancelamento = () => {
    const permited = ['WAITING_BILLING', 'WAITING_RELEASE']
    const result = permited.includes(originalStatus as string)
    return result;
  }
  const TableRow = ({ item }) => {
    return (
      <Tr>
        { permiteCancelamento() ?
          <Td>
            <Checkbox isChecked={isCheckedProduct(item)} size={'md'} colorScheme="red" 
              onChange={({target}) => addCancelamento(item, target.checked)}/>
          </Td> 
        : null }
        <Td>{item.crop}</Td>
        <Td>{item.productName}</Td>
        <Td>{item.quantity}</Td>
        <Td>{item.quantitySaved}</Td>
      </Tr>
    );
  };
  const SectionProducs = () => {
    return (
    <>
      <VStack p={2} spacing={4} align={'flex-start'}>
        <HStack w={'100%'} pr={4} onClick={() => setCollapseProducts(!collapseProducts)}>
          <ActionCollpase collapse={collapseProducts} />
          <Tooltip label={collapseProducts ? 'Mostrar': 'Ocultar'}>
            <Heading size="md">{'Produtos: '}</Heading>
          </Tooltip>
        </HStack>
                
        <Collapse in={!collapseProducts}>
          <Table >
            <Thead>
              <Tr alignItems="center" justifyContent="center">                
                { permiteCancelamento()  ?
                  <Th></Th>
                : null }
                <Th>Safra</Th>
                <Th>Produto</Th>
                <Th>Quantidade pedida</Th>
                <Th>Quantidade entregue</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(objectData?.products || []).map((p) => (
                <TableRow key={p.deliveryOrderId} item={p} />
              ))}
            </Tbody>
          </Table>
        </Collapse>
        <EATextBoxDialog
            isOpenDialog={showTextBox}
            textHeader="Cancelamento"
            label="informe o motivo do cancelamento"
            required
            textActionOne="OK"
            actionOne={(value) => cancelarItens(value) }
            actionOneProps={{
              colorScheme:"blue"
            }}
            textActionTwo="Cancelar"
            actionTwo={(value) => {
              setShowTextBox(false) 
            }}
            actionTwoProps={{
              colorScheme:"red"
            }}
          />
      </VStack>
    </>
    );
  }
  
  const SectionAttachments = () => {
    return (
      <>
        <VStack p={2} spacing={2} align={'flex-start'}>                
          <HStack w={'100%'} pr={4} >  
            <ActionCollpase collapse={collapseAttachment} onClick={() => setCollapseAttachment(!collapseAttachment)} />
            <Tooltip label={collapseAttachment ? 'Mostrar': 'Ocultar'}>
              <Heading size="md" onClick={() => setCollapseAttachment(!collapseAttachment)}>{'Anexos: '}</Heading>
            </Tooltip>
            {originalStatus !== 'CANCELED' && 
              <IconButton
                aria-label="Add"
                size="sm"
                color="#FFFFFF"
                backgroundColor="green"
                onClick={() => {
                  setCollapseAttachment(false)
                  setInputFiles([{}, ...inputFiles])
                }}
                icon={<FontAwesomeIcon icon={faPlus} />}
              />
            }
            
          </HStack >  
            <Collapse in={!collapseAttachment} style={{width: '100%'}}>
              <VStack spacing={1}>
                {(inputFiles || []).map((a, index) => (
                  a.fileId ?
                  <EAInputDownload
                    key={'File_'+index+'_'+AppUtil.randomId()}
                    name={'File_'+index+'_'+AppUtil.randomId()}
                    fileActionDelete={(file: InputFile)=> actionDelete(file, index)}
                    fileActionDownload={actionDownload}
                    deleting={isDeleting}
                    downloding={isDownloading}
                    downlodingProgress={downloadingProgress}
                    isReadOnly={action === 'VIEW'}
                    file={a}
                  />
                  :                                
                  <EAInputUpload
                    key={'File_'+index+'_'+AppUtil.randomId()}
                    name={'File_'+index+'_'+AppUtil.randomId()}
                    type='file'
                    label="Anexo"
                    accept="application/pdf, .pdf"
                    fileActionInput={(file: InputFile)=> actionInput(file, index)}
                    isReadOnly={action === 'VIEW'}
                    uploading={inputFiles[index].fileName ? isUploading : false}
                    uploadingProgress={inputFiles[index].fileName ? uploadingProgress: 0}
                    file={inputFiles[index]}
                    fileActionDelete={(file: InputFile)=> actionDelete(file, index)}
                  />
                ))
              }
            </VStack>
          </Collapse>
        </VStack>
      </>
    );
  }


  return (
    <>
      {isDownloading && <LoadingBackdrop open={isDownloading} />}
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={closeForm}
        size="6xl"
      >
        <ModalOverlay />
        <ModalContent height="85vh">
          <ModalHeader textAlign="center">
            {`Solicitação de retirada ${objectData.deliveryCode || objectData.producerName}`}
          </ModalHeader>
          <ModalBody pb={8} overflowX="auto">
            <Stack spacing="4">              
              <EAForm formRef={formRef} onSubmit={submit} yupSchema={formSchema}>
                <SectionDelivey/>
                <SectionProducs/>
                {originalStatus !== 'WAITING_BILLING' && <SectionAttachments/>}
              </EAForm>
            </Stack>
          </ModalBody>
          <ModalFooter>
            { permiteCancelamento() && !collapseProducts ?
              <Tooltip label={'Permite o cancelamento dos produtos selecionados!'}>
                <Button
                  isDisabled={action === 'VIEW' || isDeleting || isSaving || !canceledProducts || canceledProducts.length === 0}
                  isLoading={isSaving}
                  loadingText="deleting..."
                  colorScheme="red"
                  mr={3}
                  leftIcon={<FontAwesomeIcon icon={faTrash} />}
                  onClick={() => setShowTextBox(true) }>
                  Cancelar Item
                </Button>
              </Tooltip> 
              : null }
            <Spacer/>
            <Button
              isDisabled={action === 'VIEW' || isDeleting || isSaving}
              isLoading={isSaving}
              loadingText="Saving..."
              colorScheme="green"
              mr={3}              
              leftIcon={<FontAwesomeIcon icon={faSave} />}
              onClick={() => formRef.current?.submitForm()}>
              Salvar
            </Button>
            <Button 
              isDisabled={isDeleting || isSaving}
              colorScheme="blackAlpha" 
              leftIcon={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => closeForm(false)}>
              Fechar
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DeliveryOrderModal;
