/* eslint-disable no-underscore-dangle */
import {
  Button,
  Checkbox, Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack, Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td, Th,
  Thead,
  Tr,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputSwitch from '../../components/Form/EAInputSwitch';
import { ICooperative } from '../../dtos/cooperative';
import { ICooperativePermission, IPermissions, IUser } from '../../dtos/user';
import api from '../../services/api';

type UserFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IUser) => void;
};

export interface IFeaturesWeb {
  description: string;
  feature: string;
  byCooperative: boolean;
}
export interface UserPermission {
  active: boolean;
  feature: string;
  description: string;
  cooperatives?: Array<ICooperativePermission>
  byCooperative?: boolean;
}

const UserModal = ({ dataId, action, onCloseForm }: UserFormProps) => {
  const Toast = useToast();
  const [objectData, setObjectData] = useState<IUser>({} as IUser);
  const [featuresWeb, setFeaturesWeb] = useState<Array<IFeaturesWeb>>([] as Array<IFeaturesWeb>);
  const [userPermissionData, setUserPermissionData] = useState<Array<UserPermission>>([] as Array<UserPermission>);
  const [cooperatives, setCooperatives] = useState<Array<ICooperative>>([] as Array<ICooperative>);
  const formRef = useRef<FormHandles>(null);
  const [load, setLoad] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formSchema = {
    id: Yup.string().default(dataId),
    login: Yup.string().trim('Não é permitido espaços em branco').required('Login é obrigatória'),
    password: Yup.string().when('id', { is: ((value) => !value || value === ''), then: Yup.string().required('Senha obrigatória') }),
    name: Yup.string().required('Nome é obrigatório'),
    email: Yup.string().required('Email é obrigatório').email('Email inválido!'),
  }
  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getCooperatives = async () => {
    setLoad(true);
    const response: AxiosResponse = await api.get(`/cooperative?sort=code`);
    if (response && response.data) {
      setCooperatives(response.data.filter((f: ICooperative)=> !!f?.active));
    }
  };

  const getFeatures = async () => {
    setLoad(true);
    const response: AxiosResponse = await api.get(`/features/web`);
    if (response && response.data) {
      setFeaturesWeb(response.data);
    }
  };
  const getDocument = async (id: string) => {
    setLoad(true);
    const response: AxiosResponse<IUser> = await api.get<IUser>(`/user/${id}`);
    if (response && response.data) {
      setObjectData(response.data);
    }
  };

  useEffect(() => {
    setLoad(false);
  }, [objectData]);


  useEffect(() => {
    setLoad(true);
    const featurePermissions: UserPermission[] = featuresWeb.map( (f:IFeaturesWeb)=> ({
      cooperatives: objectData?.userPermissions?.find((up: IPermissions) => up.permission === f.feature)?.cooperatives || [],
      active: objectData?.userPermissions?.find((up: IPermissions) => up.permission === f.feature)?.active || false,
      feature: f.feature,
      description: f.description,
      byCooperative: f.byCooperative || false
    }))
    setUserPermissionData(featurePermissions)
    setLoad(false);
  }, [objectData, featuresWeb]);

  const submit = async ({login, password, name, email}) => {
    try {
      const userPermissions: IPermissions[] = userPermissionData?.filter(p=> p.active)?.map(p=>({
        permission: p.feature,
        cooperatives: p.cooperatives || [],
        active: true
      })) || []
      const dataToSave = {
        ...objectData,
        login,
        password,
        name,
        email,
        userPermissions: userPermissions,
      };

      const afterActive = dataToSave.active === undefined ? true : dataToSave.active
      const saved: AxiosResponse<IUser> = await api.post<IUser>('/user/save', dataToSave);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: dataId ? 'Registro atualizado com sucesso!' : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      if (saved?.data && !saved?.data.active && !!afterActive && (saved?.data.userPermissions||[]).length === 0 ){
        Toast({
          status: 'info',
          title: 'Informação',
          description: 'Cadastro inativo por não ter permissões liberadas!',
          position: 'top-right',
        });
      }
      closeForm(true, saved.data);
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err.message ,
        position: 'top-right',
      });
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      await getFeatures();
      await getCooperatives();
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };

  const isSelect = (_objectData: IUser, permission: string) => {
    if (!(_objectData && _objectData.userPermissions && _objectData.userPermissions.length > 0)) return false;
    return _objectData.userPermissions.filter(f=> !!f.active && f.permission === permission).length > 0
  };


  return (
    <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={closeForm} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {action === 'VIEW' ? 'Visualizar usuário' : dataId ? 'Atualizar usuário' : 'Novo Usuário'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8} overflowX="auto">
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={formSchema}>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Informações do usuário</Tab>
                <Tab>Permissões</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing="4">
                    <EAInput
                      name="id"
                      isInvisible
                      defaultValue={ dataId || '' }
                    />
                    <EAInput
                      name="login"
                      label="Login"
                      isRequired
                      isReadOnly={!!dataId}
                      defaultValue={ objectData.login || ''}
                      autoComplete="none"
                    />
                    {action !== 'VIEW' && (
                      <EAInput
                        name="password"
                        label="Senha"
                        isRequired={!dataId}
                        type="password"
                        defaultValue={ objectData.password || ''}
                        autoComplete="none"
                      />
                    )}
                    <EAInput
                      name="name"
                      label="Nome"
                      isRequired
                      isReadOnly={action === 'VIEW'}
                      defaultValue={ objectData.name || ''}
                      autoComplete="none"
                    />
                    <EAInput
                      name="email"
                      label="Email"
                      isRequired
                      isReadOnly={action === 'VIEW'}
                      defaultValue={ objectData.email || ''}
                      autoComplete="none"
                    />
                    <EAInputSwitch
                      name="newPassword"
                      label="Solicitar nova senha"
                      colorScheme="green" size="lg"
                      isReadOnly={action === 'VIEW'}
                      defaultChecked={objectData?.newPassword === undefined ? false : objectData.newPassword}
                      onChange={() => {
                        objectData.newPassword = !objectData.newPassword
                      }}
                    />
                    <EAInputSwitch
                      name="active"
                      label="Ativo"
                      isReadOnly={action === 'VIEW'}
                      defaultChecked={objectData?.active === undefined ? true : objectData.active}
                      onChange={() => {
                        objectData.active = !objectData.active
                      }}
                    />
                    {/*
                    <HStack justifyContent="space-between" alignItems="center">
                      <Text>request new password</Text>
                      <Switch
                        isDisabled={action === 'VIEW'}
                        colorScheme="green" size="lg"
                        defaultChecked={objectData?.newPassword === undefined ? false : objectData.newPassword}
                        onChange={() => {
                          objectData.newPassword = !objectData.newPassword
                        }} />
                    </HStack>
                    <HStack justifyContent="space-between" alignItems="center">
                      <Text>Active</Text>
                      <Switch
                        isDisabled={action === 'VIEW'}
                        colorScheme="green" size="lg"
                        defaultChecked={objectData?.active === undefined ? true : objectData.active}
                        onChange={() => {
                          objectData.active = !objectData.active
                        }} />
                    </HStack>*/
                    }
                  </Stack>
                </TabPanel>
                <TabPanel>
                  <Table>
                    <Thead>
                      <Tr alignItems="center" justifyContent="center">
                        <Th>Ação</Th>
                        {featuresWeb?.filter(f=> !!f.byCooperative).length > 0 && <Th>Cooperatives</Th>}
                      </Tr>
                    </Thead>
                    <Tbody>
                      {userPermissionData.map((permission, idxPermission) => (
                        <Tr key={idxPermission} alignItems="center" justifyContent="center">
                          <Td>
                            <Checkbox
                              pr={2}
                              isDisabled={action === 'VIEW'}
                              defaultValue={permission.feature}
                              defaultChecked={isSelect(objectData, permission.feature)}
                              onChange={() => {permission.active = !permission.active} }
                            /><span>{permission.description} </span>
                          </Td>
                          {permission.byCooperative && <Td>{permission.cooperatives?.map(c=>c.code).join(', ')}</Td>}
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </EAForm>
        </ModalBody>

        <ModalFooter>
          <Button isDisabled={action === 'VIEW'} 
            colorScheme="green" mr={3} 
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSubmit}>
            Salvar
          </Button>
          <Button
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserModal;
