/* eslint-disable no-underscore-dangle */
import { Box, HStack, Stack, Text, Input } from '@chakra-ui/react';
import { useToast } from '@chakra-ui/toast';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DataTableComponent, {
  ColumnHeaders,
  getRowsPerPage,
} from '../../components/DataTable';
import { ISelectOptions } from '../../components/Form/EASelect';
import { IList } from '../../dtos/commons';
import {
  EDeliveryOrderStatus,
  IDeliveryOrder,
} from '../../dtos/delivery-order';
import api from '../../services/api';
import DateUtil from '../../utils/DataUtil';
import DiffData from '../../utils/DiffData';
import DeliveryOrderModal from './DeliveryOrderModal';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

interface IDeliveryOrderData extends IDeliveryOrder {
  dateInStatus: string;
  dateOfCreation: string;
}

const DeliveryOrder = () => {
  const availableStatus = ['WAITING_BILLING', 'IN_SEPARATION', 'WAITING_INVOICE', 'WAITING_RELEASE', 'RELEASED', 'CANCELED']
  const defaultStatus = ['WAITING_BILLING', 'WAITING_INVOICE', 'WAITING_RELEASE']
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>(
    'DEFAULT',
  );
  const [dataId, setDataId] = useState('');
  const [DeliveryOrderList, setDeliveryOrderList] = useState<IDeliveryOrder[]>(
    [],
  );
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string|undefined>('-dateOf');
  const [optionsStatus] = useState<ISelectOptions[]>(availableStatus.map(s => ({ label: EDeliveryOrderStatus[s], value: s})))
  const [selectedStatus, setselectedStatus] = useState<ISelectOptions[]>(defaultStatus.map(s => ({ label: EDeliveryOrderStatus[s], value: s})))
  const [initDate, setInitialDate] = useState<string>(DateUtil.stringFormatt(DateUtil.addDays(new Date(), -7), 'YYYY-MM-DD'));
  const [finishDate, setFinishDate] = useState<string>(DateUtil.stringFormatt(new Date(), 'YYYY-MM-DD'));
  /*

  
  */

  const getData = async ({
    filter,
    sort,
    page,
    offset,
    limit = rowsPerPage,
  }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      
      if (initDate){
        query.push(`startdate=${ moment(initDate).toJSON() }`);
      }
      if (finishDate){
        query.push(`enddate=${ moment(finishDate).toJSON() }`);
      }
      
      if (selectedStatus && selectedStatus.length>0){
        query.push(`status=${ selectedStatus.map(v=>v.value).join(',')}`);
      }
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setDeliveryOrderList([]);
      setAllRows(0);
      const response: AxiosResponse<IList<IDeliveryOrder>> = await api.get(
        `/delivery-order/list${query.length > 0 ? `?${query.join('&')}` : ''}`,
      );
      if (response && response.data) {
        const docs: IDeliveryOrder[] = response.data.rows || [];
        setDeliveryOrderList(
          docs.map((r) => ({
            ...r,
            dateOf: moment(r.dateOf).format('DD/MM/YYYY'),
            status: EDeliveryOrderStatus[r.status],
            dateInStatus: DiffData(
              (new Date(r.dateStatus) || new Date()).getTime(),
            ),
            dateOfCreation: DiffData(new Date(r.createdAt || r.dateOf).getTime()),
          })),
        );
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };
  
  useEffect(() => {
    getData({});
  }, [initDate, finishDate, selectedStatus]);
  useEffect(() => {
    setHeaders([
      { text: 'Ordem', value: 'code', align: 'center', sortable: true },
      { text: 'Criada Em', value: 'dateOf', align: 'center', sortable: true },
      {
        text: 'Cooperado',
        value: 'producerName',
        alignHeader: 'start',
        sortable: true,
        type: 'string',
      },
      {
        text: 'Status',
        value: 'status',
        alignHeader: 'center',
        align: 'center',
        sortable: true,
        type: 'string',
      },
      {
        text: 'No Status',
        value: 'dateInStatus',
        alignHeader: 'center',
        align: 'center',
        type: 'string',
      },
      {
        text: 'Em Atendimento',
        value: 'dateOfCreation',
        alignHeader: 'center',
        align: 'center',
        type: 'string',
      },
    ]);
    getData({ sort: 'dateOf' });
  }, []);

  return (
    <>
      {showModal ? (<DeliveryOrderModal dataId={dataId} action={actionModal} onCloseForm={closeForm} />) : null}
      <Stack d="flex" w="100%">
       <HStack>
        <Text>Periodo: </Text>
        <Input
            placeholder="Data Inicial"
            type='date'
            maxW="200px"
            minW="180px"
            value={initDate}
            onChange={(event) => {
              setInitialDate(event.target.value);
            }}
          />
          <Input
            placeholder="Data Final"
            type='date'
            maxW="200px"
            minW="180px"
            value={finishDate}
            onChange={(event) => {              
              setFinishDate(event.target.value);              
            }}
          />
          <Text>Status: </Text>
          <Box w="100%">
            <Select
              placeholder="Selecione uma ou mais opções"
              isClearable
              isMulti
              options={optionsStatus}
              value={selectedStatus}
              onChange={(event) => {
                setselectedStatus(event as ISelectOptions[]);
              }}
            />
          </Box>
          
        </HStack>
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={DeliveryOrderList}
        filter={filterValue}        
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        // newItem={() => {
        //   setShowModal(true);
        //   setActionModal('EDIT');
        // }}
        itemActionEdit={(item: IDeliveryOrder) => {
          setDataId(item.key);
          setShowModal(true);
          setActionModal('EDIT');
        }}
        columnSortable={sortable}
      />
      </Stack>
    </>
  );
};

export default DeliveryOrder;
