import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  useToast
} from '@chakra-ui/react';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import fileDownload from 'js-file-download';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputDownload from '../../components/Form/EAInputDownload';
import EAInputSwitch from '../../components/Form/EAInputSwitch';
import EAInputUpload, { InputFile } from '../../components/Form/EAInputUpload';
import EATextarea from '../../components/Form/EATextarea';
import EAModalVideoPlayer from '../../components/Form/EAModalVideoPlayer';
import { IAssayResult } from '../../dtos/assay-result';
import api from '../../services/api';


type AssayResultFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IAssayResult) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const AssayResultModal = ({ dataId, action, onCloseForm }: AssayResultFormProps) => {
  const Toast = useToast();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingVideo, setIsLoadingVideo] = useState(true);
  const [objectData, setObjectData] = useState<IAssayResult>({} as IAssayResult);
  const [inputFile, setInputFile] = useState<InputFile>({});
  const [inputVideo, setInputVideo] = useState<InputFile>({});
  const formRef = useRef<FormHandles>(null);
  // const [, setLoad] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploadingProgress, setUploadingProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadingProgress, setDownloadingProgress] = useState(0);

  const [isVideoUploading, setIsVideoUploading] = useState(false);
  const [isVideoDeleting, setIsVideoDeleting] = useState(false);
  const [videoUploadingProgress, setVideoUploadingProgress] = useState(0);
  const [isVideoDownloading, setIsVideoDownloading] = useState(false);
  const [videoDownloadingProgress, setVideoDownloadingProgress] = useState(0);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);

  const yupSchema = {
    purpose: Yup.string().required('Objetivo é obrigatória!'),
    description: Yup.string().required('Descrição é obrigatória!'),
  }

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    // setLoad(true);
    const response: AxiosResponse = await api.get(`/assayresult/${id}`);
    if (response && response.data) {
      const doc: IAssayResult = response.data
      if (doc && doc.files && doc.files.length > 0){
        setInputFile({ fileId: doc.files[0]?.assayResultFileId, fileName: doc.files[0]?.fileName})
      }
      if (doc && doc.videos && doc.videos.length > 0){
        setInputVideo({ fileId: doc.videos[0]?.assayResultVideoId, fileName: doc.videos[0]?.fileName, mimetype: doc.videos[0]?.mimetype, qualities: doc.videos[0]?.qualities})
      }
      setObjectData(doc);
      setIsLoadingVideo(false)
    }
  };

  const actionDownload = useCallback(async (e: InputFile) => {
    setIsDownloading(true)
    setDownloadingProgress(0)
    try {
      const responseDownloader: AxiosResponse = await api.get(`/assayresult/${objectData._id}/download/${e.fileId}`, {
        responseType: 'blob',
        onDownloadProgress: (event) => {
          onProgressDownload(event)
      } });
      setDownloadingProgress(100)
      const { data, headers } = responseDownloader
      const contentType = headers['content-type']
      const contentName = headers['content-name']
      fileDownload(data, contentName, contentType)
      setIsDownloading(false)
      setDownloadingProgress(0)
    } catch (error) {
      setIsDownloading(false)
      setDownloadingProgress(0)
      Toast({
        status: 'error',
        title: 'Erro ao baixar arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);

  const actionDelete = useCallback(async (e) => {
    try {
      setIsDeleting(true);
      const response: AxiosResponse = await api.delete(`/assayresult/${objectData._id}/file/${e.fileId}`);
      setInputFile({})
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Arquivo excluído com sucesso!',
        position: 'top-right',
      });
      setIsDeleting(false);
      setReload(true)
    } catch (error) {
      setIsDeleting(false);
      Toast({
        status: 'error',
        title: 'Erro ao excluir arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);

  

  const actionDownloadVideo = useCallback(async (e: InputFile) => {
    setIsVideoDownloading(true)
    setVideoDownloadingProgress(0)
    try {
      const responseDownloader: AxiosResponse = await api.get(`/assayresult/${objectData._id}/download-video/${e.fileId}`, {
        responseType: 'blob',
        onDownloadProgress: (event) => {
          onProgressVideoDownload(event)
      } });
      setVideoDownloadingProgress(100)
      const { data, headers } = responseDownloader
      const contentType = headers['content-type']
      const contentName = headers['content-name']
      fileDownload(data, contentName, contentType)
      setIsVideoDownloading(false)
      setVideoDownloadingProgress(0)
    } catch (error) {
      setIsVideoDownloading(false)
      setVideoDownloadingProgress(0)
      Toast({
        status: 'error',
        title: 'Erro ao baixar arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);

  const actionDeleteVideo = useCallback(async (e) => {
    try {
      setIsDeleting(true);
      const response: AxiosResponse = await api.delete(`/assayresult/${objectData._id}/video/${e.fileId}`);
      setInputVideo({})
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: 'Arquivo excluído com sucesso!',
        position: 'top-right',
      });
      setIsVideoDeleting(false);
      setReload(true)
    } catch (error) {
      setIsVideoDeleting(false);
      Toast({
        status: 'error',
        title: 'Erro ao excluir arquivo!',
        description: error?.message,
        position: 'top-right',
      });
    }
  }, []);


  const actionInput = useCallback((e) => {
    setInputFile(e)
  }, []);

  const actionInputVideo = useCallback((e) => {
    setInputVideo(e)
  }, []);

  const onProgressUpload = useCallback((progressEvent) => {
    const percentFinal = Math.ceil(((progressEvent.total / 100)*3))
    setUploadingProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total + percentFinal)))
  }, []);


  const onProgressDownload = useCallback((progressEvent) => {
    setDownloadingProgress(Math.round( (progressEvent.loaded * 100) / (progressEvent.total) ))
  }, []);

  const onProgressVideoUpload = useCallback((progressEvent) => {
    const percentFinal = Math.ceil(((progressEvent.total / 100)*3))
    setVideoUploadingProgress(Math.round((progressEvent.loaded * 100) / (progressEvent.total + percentFinal)))
  }, []);
  
  const onProgressVideoDownload = useCallback((progressEvent) => {
    setVideoDownloadingProgress(Math.round( (progressEvent.loaded * 100) / (progressEvent.total) ))
  }, []);

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
      setIsLoading(false)
    }

    getDataFromAPI();
  }, []);
  /*
  useEffect(() => {
    setLoad(false);
  }, [objectData]);
  */
  // https://www.sanity.io/guides/form-validation-with-npm-yup
  // https://chakra-ui.com/docs/form/form-control#making-a-field-required
  // https://blog.logrocket.com/the-ultimate-roundup-of-react-form-validation-solutions/
  const submit = async (data) => {
    try {
      setIsSaving(true)
      const newData: IAssayResult = {
        ...objectData,
        ...data,
        publishApp: objectData.publishApp
      };
      const saved = await api.post('/assayresult/save', newData);
      setObjectData(saved?.data)
      const { _id } = saved?.data

      if (inputFile && inputFile.file && !inputFile.fileId && _id){
        setIsUploading(true)
        setUploadingProgress(0);
        const formData = new FormData();
        formData.append("file", inputFile.file);
        const savedFile = await api.post(`/assayresult/${_id}/upload`, formData, {
          onUploadProgress: (event) => {
            onProgressUpload(event)
        } });
        setUploadingProgress(98);
        setUploadingProgress(99);
        setUploadingProgress(100);
        setIsUploading(false)
      }

      // video - https://creatomate.com/blog/video-rendering-with-nodejs-and-ffmpeg
      

      if (inputVideo && inputVideo.file && !inputVideo.fileId && _id){
        setIsVideoUploading(true)
        setVideoUploadingProgress(0);
        const formData = new FormData();
        formData.append("file", inputVideo.file);
        const savedFile = await api.post(`/assayresult/${_id}/video-upload`, formData, {
          onUploadProgress: (event) => {
            onProgressVideoUpload(event)
        } });
        setUploadingProgress(98);
        setUploadingProgress(99);
        setUploadingProgress(100);
        setIsUploading(false)
      }
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: dataId
          ? 'Registro atualizado com sucesso!'
          : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      setIsSaving(false)
      closeForm(true, saved.data);
    } catch (err) {
      setIsSaving(false)
      setUploadingProgress(0);
      setIsUploading(false)
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };
  
  const getURLStreaming = (video: InputFile, quality?: string) : string | undefined => {
    if (!video || (!video.fileId && !video.filePath) ){
      return undefined
    } else if (!video.fileId){
      // console.log('getURL Video path: ', video.filePath)
      return video.filePath
    } else {
    // const maxResolution = video.qualities && video.qualities.length > 0 ? Math.max(...video.qualities.map(m => parseInt((m.replace('p', '')), 10))) : 360
    let query = `authorization=${api.defaults?.headers?.authorization?.replace('Bearer ', '')}`;
    if (quality && video.qualities && video.qualities.length > 0 && video.qualities.includes(quality)){
      query = `quality=${quality}&${query}`
    }
    const u = `${api.defaults.baseURL}assayresult/${objectData._id}/video-streaming-file/${video.fileId}/video.mp4?${query}`
    // console.log('getURLStreaming: ', u)
     return u;
  }
  };

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };
  return (
    <>
    { isLoading ? 
      null :
      <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader >
          {action === 'VIEW'
            ? 'Visualizar Resultado de Ensaio'
            : dataId
            ? 'Atualizar Resultado de Ensaio'
            : 'Novo Resultado de Ensaio'}
        </ModalHeader>
        <ModalBody pb={8} overflowX="auto">
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={yupSchema}>
            <Stack spacing="2">
              <EAInput
                name="purpose"
                label="Objetivo"
                // placeholder="Objetivo"
                isRequired
                isReadOnly={action === 'VIEW'}
                defaultValue={ objectData?.purpose || ''}
                autoComplete="none"
              />
              <EATextarea
                name="description"
                label="Descrição"
                // placeholder="Descrição"
                isRequired
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData.description||''}
                autoComplete="none"
              />
              <EAInput
                name="culture"
                label="Cultura"
                // placeholder="Cultura"
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData.culture||''}
                autoComplete="none"
              />
              <EAInput
                name="crop"
                label="Safra"
                // placeholder="Safra"
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData.crop||''}
                autoComplete="none"
              />
              <EAInputSwitch
                name="publishApp"
                label="Publicar no APP?"
                defaultChecked={objectData?.publishApp === undefined ? false : objectData.publishApp}
                isReadOnly={action === 'VIEW'}
                onChange={() => {
                  objectData.publishApp = !objectData.publishApp
                }}
              />
              { inputFile && inputFile.fileId ?
                <EAInputDownload
                  name="file"
                  fileActionDelete={actionDelete}
                  fileActionDownload={actionDownload}
                  deleting={isDeleting}
                  downloding={isDownloading}
                  downlodingProgress={downloadingProgress}
                  isReadOnly={action === 'VIEW'}
                  file={inputFile}
                />
                :
                <EAInputUpload
                  name="file"
                  type='file'
                  label="Anexo"
                  accept="application/pdf, .pdf"
                  fileActionInput={actionInput}
                  isReadOnly={action === 'VIEW'}
                  uploading={isUploading}
                  uploadingProgress={uploadingProgress}
                  file={inputFile}
                  defaultValue={inputFile?.fileName||''}
                />
              }
              { inputVideo && inputVideo.fileId?
                <EAInputDownload
                  name="Video"
                  isVideoPlayer={true}
                  urlVideoPlayer={getURLStreaming(inputVideo)}
                  fileActionDelete={actionDeleteVideo}
                  fileActionDownload={actionDownloadVideo}
                  deleting={isVideoDeleting}
                  downloding={isVideoDownloading}
                  downlodingProgress={videoDownloadingProgress}
                  isReadOnly={action === 'VIEW'}
                  file={inputVideo}
                />
                :
                <EAInputUpload
                  name="Video"                  
                  isVideoPlayer
                  type='file'
                  label="Video"
                  accept="video/mp4, .mp4, video/x-msvideo, .avi"
                  fileActionInput={actionInputVideo}
                  isReadOnly={action === 'VIEW'}
                  uploading={isVideoUploading}
                  uploadingProgress={videoUploadingProgress}
                  file={inputVideo}
                  defaultValue={inputVideo?.fileName||''}
                />
              }
            </Stack>
          </EAForm>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW' || isDeleting || isSaving}
            isLoading={isSaving}
            loadingText="Saving..."
            colorScheme="green"
            mr={3}             
            leftIcon={<FontAwesomeIcon icon={faSave} />}            
            onClick={handleSubmit}>
            Salvar
          </Button>
          <Button 
            isDisabled={ isDeleting || isSaving }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(reload)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>

    }
    </>
    
  );
};

export default AssayResultModal;
