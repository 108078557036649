import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react"
import React from "react"
import ParameterGeneral from "./ParameterGeneral"
import ParameterWarehouse from "./ParameterWarehouse"

export function ParametersGeneral() {
  return (
    <Tabs  w={'100%'}>
      <TabList>
        <Tab>Depósito/Carregamento</Tab>
        <Tab>Geral</Tab>
      </TabList>

      <TabPanels>
        <TabPanel w={'100%'}>
          <ParameterWarehouse/>
        </TabPanel>
        <TabPanel w={'100%'}>
          <ParameterGeneral/>
        </TabPanel>
      </TabPanels>
    </Tabs>

    
  )
};
