import React, { useEffect, useMemo, useState } from 'react';
import { Flex, HStack, IconButton, Spacer, StyleProps } from '@chakra-ui/react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDoubleLeft, faAngleDoubleRight, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export interface PaginationProps extends StyleProps {
  itemsPerPageOptions?: number[];
  itemsPerPage: number;
  allItems: number; // all itens
  page?: number;
  pageChange?: (page: number, skip: number, limit: number) => void; // page, offset and limit
}

function PaginationComponent(props: PaginationProps) {
  const { itemsPerPage, pageChange, page, allItems } = props;
  const pageMemo: number = useMemo(() => page || 1, [page]);
  const allItemsMemo: number = useMemo(() => allItems || 0, [allItems]);
  const [currentPage, setcurrentPage] = useState(pageMemo);
  const [allPages, setAllPages] = useState(0);

  const itemsPerPageMemo: number = useMemo(() => itemsPerPage || 5, [itemsPerPage]);
  useEffect(() => {
    setAllPages(Math.ceil(allItemsMemo / itemsPerPageMemo));
  }, [allItemsMemo, itemsPerPageMemo]);

  const changePage = (value: number) => {
    setcurrentPage(value);
    if (pageChange) {
      pageChange(value, (value - 1) * itemsPerPage, itemsPerPage);
    }
  };

  return (
    <>
      <HStack w="100%" alignSelf="self-end"   
        borderBottomRadius="6px"
        borderTopRightRadius="6px"
        pb="1"
      >
        <Flex pl="2">{`Page ${pageMemo} of ${allPages}`}</Flex>
        <Spacer />
        <Flex px="4">{`${allItemsMemo} Records`}</Flex>
        <IconButton
          aria-label="First"
          size="sm"
          // color="green"
          // variant="outline"
          colorScheme="green"
          isDisabled={currentPage === 1}
          onClick={() => changePage(1)}
          icon={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
        />
        <IconButton
          aria-label="Previous"
          size="sm"
          // color="green"
          // variant="outline"
          colorScheme="green"
          isDisabled={currentPage === 1}
          onClick={() => currentPage - 1 >= 1 && changePage(currentPage - 1)}
          icon={<FontAwesomeIcon icon={faAngleLeft} />}
        />
        <IconButton
          aria-label="Next"
          size="sm"
          // color="green"
          // variant="outline"
          colorScheme="green"
          isDisabled={currentPage >= allPages}
          onClick={() => currentPage + 1 <= allPages && changePage(currentPage + 1)}
          icon={<FontAwesomeIcon icon={faAngleRight} />}
        />
        <IconButton
          aria-label="Last"
          size="sm"
          // color="green"
          colorScheme="green"
          // variant="outline"
          isDisabled={currentPage >= allPages}
          onClick={() => changePage(allPages)}
          icon={<FontAwesomeIcon icon={faAngleDoubleRight} />}
        />
      </HStack>
    </>
  );
}

export default PaginationComponent;
