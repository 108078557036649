/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import DataTableComponent, { ColumnHeaders, getRowsPerPage } from '../../components/DataTable';
import { IEventMobile } from '../../dtos/event-mobile';
import EventMobileModel from './EventMobileModel';
import DateUtil from '../../utils/DataUtil';

interface GetData {
  filter?: string;
  sort?: string;
  page?: number;
  offset?: number;
  limit?: number;
}

const EventMobile = () => {
  const [showModal, setShowModal] = useState(false);
  const [actionModal, setActionModal] = useState<'VIEW' | 'EDIT' | 'DEFAULT'>('DEFAULT');
  const [dataId, setDataId] = useState('');
  const [EventMobileList, setEventMobileList] = useState<IEventMobile[]>([]);
  const [filterValue, setFilterValue] = useState<string>();
  const [rowsPerPage] = useState(getRowsPerPage());
  const [currentPage, setCurrentPage] = useState(1);
  const [allRows, setAllRows] = useState(0);
  const [headers, setHeaders] = useState<ColumnHeaders[]>([]);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [sortable, setSsortable] = useState<string>();  

  const getData = async ({ filter, sort, page, offset, limit = rowsPerPage }: GetData) => {
    try {
      setLoading(true);
      setCurrentPage(page || 1);
      setFilterValue(filter);
      setSsortable(sort);
      const query: string[] = [];
      if (filter) {
        query.push(`q=${filter}`);
      }
      if (sort) {
        query.push(`sort=${sort}`);
      }
      if (offset) {
        query.push(`skip=${offset}`);
      }
      if (limit) {
        query.push(`limit=${limit}`);
      }

      setEventMobileList([]);
      setAllRows(0);
      const response: AxiosResponse = await api.get(`/eventmobile/list${query.length > 0 ? `?${query.join('&')}` : ''}`);
      if (response && response.data) {
        const docs: IEventMobile[] = response.data.rows || []
        setEventMobileList(docs.map((a: IEventMobile)=> ({
          ...a,
          dateOf: DateUtil.stringFormatt(new Date(a?.dateOf as string), 'DD/MM/YYYY HH:mm:ss'),
          userName: a.user?.name
        })));
        setAllRows(response.data.allRows);
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const closeForm = (reload = false) => {
    setActionModal('DEFAULT');
    setDataId('');
    setShowModal(false);
    if (reload) {
      getData({});
    }
  };

  useEffect(() => {
    setHeaders([
      { text: 'Data', value: 'dateOf', align: 'center', type: 'datetime', cellClass: {w: '13%', minW: '45px'}, sortable: true },
      { text: 'Dispositivo', value: 'deviceModel', alignHeader: 'center', cellClass: {w: '10%'}, sortable: true },
      { text: 'Versão', value: 'deviceOSVersion', alignHeader: 'center', cellClass: {w: '13%'}, sortable: true },
      { text: 'Tipo', value: 'type', align: 'center', cellClass: {w: '5%'}, sortable: true },
      { text: 'Ação', value: 'action', alignHeader: 'center', cellClass: {w: '20%'}, sortable: true },
      { text: 'Usuário', value: 'userName', alignHeader: 'center', sortable: true },
      { text: 'Mensagem', value: 'message', alignHeader: 'center', sortable: true }
    ]);
    getData({});
  }, []);

  return (
    <>
      {showModal ? <EventMobileModel dataId={dataId} action={actionModal} onCloseForm={closeForm} /> : null}
      <DataTableComponent
        isLoading={isLoading}
        headers={headers}
        itens={EventMobileList}
        filter={filterValue}
        paginationOptions={{
          allItems: allRows,
          itemsPerPage: rowsPerPage,
          page: currentPage,
        }}
        tableSync={(filter, sort, page, offset, limit) => {
          getData({ filter, sort, page, offset, limit });
        }}
        itemActionView={(item: IEventMobile) => {
          setDataId(item._id);
          setActionModal('VIEW');
          setShowModal(true);
        }}
        columnSortable={sortable}
      />
    </>
  );
};

export default EventMobile;
