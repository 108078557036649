import React, { useEffect, useRef, useState } from 'react';
import { IDataIntegrationSource } from '../../dtos/data-integration-source';
import * as Yup from 'yup';
import { Button, HStack, IconButton, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EAInputSlider from '../../components/Form/EAInputSlider';
import EAInputSwitch from '../../components/Form/EAInputSwitch';
import EAConfirmationDialog from '../../components/EAConfirmationDialog';
import { v4 } from 'uuid';
import { faKey, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';


type DataIntegrationSourceFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IDataIntegrationSource) => void;
};

const DataIntegrationSourceModal = ({ dataId, action, onCloseForm }: DataIntegrationSourceFormProps) => {

  const Toast = useToast();
  const [objectData, setObjectData] = useState<IDataIntegrationSource>({} as IDataIntegrationSource);
  const formRef = useRef<FormHandles>(null);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);
  const [openNewApiKey, setOpenNewApiKey] = useState(false)

  const yupSchema = {
    code: Yup.string().required('Código é obrigatória!'),
    name: Yup.string().required('Nome é obrigatória!'),
    apiKey: Yup.string().required('ApiKey é obrigatória!'),
  }

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const newApikey = () => {
    formRef.current?.setFieldValue('apiKey', v4().toString().replaceAll('-', '').toUpperCase())
  }

  const getDocument = async (id: string) => {
    const response: AxiosResponse = await api.get(`/dataintegration/${id}`);
    if (response && response.data) {
      const doc: IDataIntegrationSource = response.data

      setObjectData(doc);
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  const submit = async (data) => {
    try {
      setIsSaving(true)
      const newData: IDataIntegrationSource = {
        ...objectData,
        ...data
      };
      const saved = await api.post('/dataintegration/save', newData);
      const { _id } = saved?.data

      Toast({
        status: 'success',
        title: 'Sucesso',
        description: dataId
          ? 'Registro atualizado com sucesso!'
          : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      setIsSaving(false)
      closeForm(true, saved.data);
    } catch (err) {
      setIsSaving(false)
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err?.message,
        position: 'top-right',
      });
    }
  };

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader >
          {action === 'VIEW'
            ? 'Visualizar Resultado de Ensaio'
            : dataId
              ? 'Atualizar Resultado de Ensaio'
              : 'Novo Resultado de Ensaio'}
        </ModalHeader>
        <ModalBody pb={8} overflowX="auto">
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={yupSchema}>
            <Stack spacing="4">
              <EAInput
                name="code"
                label="Código"
                isRequired
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData?.code || ''}
                autoComplete="none"
              />
              <EAInput
                name="name"
                label="Nome"
                isRequired
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData?.name || ''}
                autoComplete="none"
              />
              <HStack alignContent="baseline" align="end">
                <EAInput
                  name="apiKey"
                  label="Api Key"
                  isRequired
                  isReadOnly
                  defaultValue={objectData?.apiKey || ''}
                  autoComplete="none"
                />
                <IconButton
                  ml={0}
                  colorScheme='green'
                  aria-label='New Api Key'
                  icon={<FontAwesomeIcon icon={faKey} />}
                  onClick={() => !objectData?.apiKey ? newApikey() : setOpenNewApiKey(true)}
                />
              </HStack>
              <EAInputSlider
                name="keepLogs"
                // label={`Mantes Logs por ${formRef.current?.getFieldValue('keepLogs')|| objectData?.keepLogs} Meses`}
                label={`Período em que os logs são mantidos`}
                slideMarkDiv={6}
                slideMarkText='Meses'
                min={1}
                max={24}
                isReadOnly={action === 'VIEW'}
                defaultValue={objectData?.keepLogs || 1}
              />
              <EAInputSwitch
                name="active"
                label="Ativo?"
                defaultChecked={!!objectData?.active}
                isReadOnly={action === 'VIEW'}
                onChange={() => {
                  objectData.active = !objectData.active
                }}
              />
            </Stack>
          </EAForm>
          <EAConfirmationDialog
            isOpenDialog={openNewApiKey}
            textHeader="Atenção"
            textBoby={`Confirma Geração de uma Nova Api Key? \nA Integração de Sincronização poderá ficar parada até atualização da nova Api Key.`}
            textActionOne="Sim"
            actionOneProps={{
              colorScheme: "red",
              onClick: () => {
                setOpenNewApiKey(false)
                newApikey()
              }
            }}
            textActionTwo="Não"
            actionTwoProps={{
              onClick: () => {
                setOpenNewApiKey(false)
              }
            }}
          />
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW' || isSaving}
            isLoading={isSaving}
            loadingText="Saving..."
            colorScheme="green"
            mr={3}
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSubmit}>
            Salvar
          </Button>
          <Button 
            isDisabled={ isSaving }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(reload)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default DataIntegrationSourceModal;


