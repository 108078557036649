export default class AppUtil {

  private static formatByteSize(bytes: number) {
    if(bytes < 1024) return bytes + " bytes";
    else if(bytes < 1048576) return(bytes / 1024).toFixed(3) + " KiB";
    else if(bytes < 1073741824) return(bytes / 1048576).toFixed(3) + " MiB";
    else return(bytes / 1073741824).toFixed(3) + " GiB";
  };

  private static memorySize(obj: any): number {
    var bytes = 0;

    function sizeOf(obj: any) {
      if(obj !== null && obj !== undefined) {
          switch(typeof obj) {
          case 'number':
              bytes += 8;
              break;
          case 'string':
              bytes += obj.length * 2;
              break;
          case 'boolean':
              bytes += 4;
              break;
          case 'object':
              var objClass = Object.prototype.toString.call(obj).slice(8, -1);
              if(objClass === 'Object' || objClass === 'Array') {
                  for(var key in obj) {
                      if(!obj.hasOwnProperty(key)) continue;
                      sizeOf(obj[key]);
                  }
              } else bytes += obj.toString().length * 2;
              break;
          }
      }
      return bytes;
    };
    return sizeOf(obj);
  };

  public static memorySizeOf(obj: any): string {
    if (obj === null || obj === undefined){
      return "0 bytes"
    } else if(Array.isArray(obj)){
      return this.formatByteSize(obj.map(o=> this.memorySize(o)).reduce((p,c)=> p+c))
    } else {
      return this.formatByteSize(this.memorySize(obj))
    }
  };

  public static randomId(): string {
    return (Math.random() + 1).toString(36).substring(2)
  }
}