/* eslint-disable no-nested-ternary */
import { Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { IconName } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { IMenu } from '../../dtos/commons';

interface ItemMenuProps {
  name: string;
  icon?: IconName;
  colapsed: boolean;
  submenus?: Array<IMenu>;
  action?: string;
  onClick?: () => void;
}

const ItemMenu = (data: ItemMenuProps) => {
  const [showMenu, setShowMenu] = useState(false);
  const { pathname } = useLocation();

  const click = () => {
    if (data.submenus && data.submenus.length > 0) {
      setShowMenu(!showMenu);
    } else if (!data.submenus && data.onClick) {
      data.onClick();
    }
  };

  const isActive = (route: IMenu) => {
    return route.action === pathname || route.submenus?.find((f) => f.action === pathname);
  };

  return (
    <>
      <HStack
        alignItems="center"
        justifyContent="flex-start"
        as={data.submenus && !data.action ? 'div' : 'button'}
        w="100%"
        style={{ transition: 'all 0.1s ease-in-out' }}
      >
        <Flex flexDirection="column" w="100%" h="100%">
          <Tooltip label={data.colapsed ? data.name : null} aria-label="A tooltip">
            <Flex
              pl="2"
              py={data.colapsed ? '3' : '2'}
              onClick={click}
              w="100%"
              alignItems="center"
              justifyContent="flex-start"
              _hover={{
                backgroundColor: 'gray.900',
                borderRadius: '10px'
              }}
            >
              <HStack spacing="2" alignItems="center" justifyContent="center">
                {data.icon &&
                  <FontAwesomeIcon icon={['fas', data.icon]} color="#FFFFFF" />
                }
                {!data.colapsed && (
                  <Text mt="2.5" color="#FFFFFF">
                    {data.name}
                  </Text>
                )}
              </HStack>
            </Flex>
          </Tooltip>

          {showMenu && (
            <Flex flexDirection="column" zIndex={1}>
              {data.submenus &&
                data.submenus.length > 0 &&
                data.submenus
                  .sort((a, b) => (a.order && b.order ? (a.order > b.order ? 1 : -1) : 1))
                  .map((s) => (
                    <Tooltip label={data.colapsed ? s.title: null} aria-label="A tooltip" key={s.action}>
                      <NavLink to={s.action || ''} key={s.action}>
                        <Flex
                          key={s.action}
                          py={data.colapsed ? '3' : '2'}
                          w="100%"
                          bg={isActive(s) ? 'gray.700' : 'gray.800'}
                          pl="4"
                          alignItems="center"
                          _hover={{
                            backgroundColor: 'gray.900',
                            borderRadius: '10px'
                          }}
                        >
                          <HStack spacing="2" alignItems="center" justifyContent="center">
                            {s.icon &&
                                <FontAwesomeIcon icon={['fas', s.icon]} color="#FFFFFF" />
                            }
                            {!data.colapsed && (
                              <Text mt="2.5" color="#FFFFFF">
                                {s.title}
                              </Text>
                            )}
                          </HStack>
                        </Flex>
                      </NavLink>
                    </Tooltip>
                  ))}
            </Flex>
          )}
        </Flex>
      </HStack>
    </>
  );
};

export default ItemMenu;
