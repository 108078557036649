import { Flex, Icon, IconButton, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from '@chakra-ui/react';
import { faBars, faChevronDown, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { RiNotificationLine } from 'react-icons/ri';
import { Link, NavLink } from 'react-router-dom';
import { useAuth } from '../hooks/AuthContext';
import { useSidebar } from '../hooks/Sidebar';

interface HeaderProps {
  menuAction?: () => void;
}

const Header = (props: HeaderProps) => {
  const sidebarProvider = useSidebar();
  const { menuAction } = props
  const auth = useAuth();
  const logout = () => {
    auth.signOut();
  };
  return (
    <Flex as="header" w="100%" h="7vh" mx="auto" px="4" align="center" bg="gray.800">
      {menuAction && !sidebarProvider.hideSideBar ?
        <Flex pr="2">
          <IconButton aria-label="" size="sm" icon={<FontAwesomeIcon icon={faBars}/>} onClick={menuAction}/>
        </Flex>:
        null
      }
      <NavLink to="/">
        <Text fontSize="3xl" fontWeight="bold" letterSpacing="tight" w="64" color="#FFFFFF">
          E-Cooperativa
        </Text>
      </NavLink>    
      <Flex align="center" ml="auto">
        <Icon as={RiNotificationLine} />
      </Flex>
      {!!auth?.session?.token && !sidebarProvider.hideSideBar ?
        <Menu >
          <Tooltip label={auth?.session?.user?.name} aria-label="A tooltip">
            <MenuButton color="#FFFFFF" pr={2} >
              {auth?.session?.user?.name.split(' ')[0]} <FontAwesomeIcon icon={faChevronDown} />
            </MenuButton>
          </Tooltip>
          <MenuList >
            {/*
            <Link to="/session/profile">
              <MenuItem icon={<FontAwesomeIcon icon={faUserAlt}/>}>
                Perfil
              </MenuItem>
            </Link>
            */}
            <Link to="/">
              <MenuItem icon={<FontAwesomeIcon icon={faSignOutAlt}/>} onClick={logout} >
                Sair
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      : null }
    </Flex>
  );
};

export default Header;
