/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Tab,
  Table,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  VStack,
  IconButton,
  Flex,
  Switch,
  Text,
  HStack,
} from '@chakra-ui/react';
import * as Yup from 'yup';
import { AxiosResponse } from 'axios';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import api from '../../services/api';
import SimpleInput from '../../components/Form/SimpleInput';
import addProps from '../../utils/addProps';
import AsyncSelect from 'react-select/async';
import IProducer, { IProducerSave } from '../../dtos/producer';
import { IUserMobile } from '../../dtos/user-mobile';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputSwitch from '../../components/Form/EAInputSwitch';

type UserFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IUserMobile) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const UserMobileModal = ({ dataId, action, onCloseForm }: UserFormProps) => {
  const Toast = useToast();
  const [objectData, setObjectData] = useState<IUserMobile>({} as IUserMobile);
  const [permissionList, setPermissionList] = useState<Array<PermissionList>>(
    [] as Array<PermissionList>,
  );
  // const [producers, setProducers] = useState<Array<IProducerSave>>([] as Array<IProducerSave>);
  // const [filteredProducer, setFilteredProducer] = useState<{ value: string, label: string}[]>([])
  const [selectedProducer, setSelectedProducer] = useState<{
    value: string;
    label: string;
  } | null>();
  const [inputProducer, setInputProducer] = useState<string>();
  const [isGetingProducer, setGetProducer] = useState<boolean>(false);
  const formRef = useRef<FormHandles>(null);
  const [, setLoad] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const formSchema = {
    id: Yup.string().default(dataId),
    login: Yup.string().trim('Não é permitido espaços em branco').required('Login é obrigatório'),
    password: Yup.string().when('id', { is: ((value) => !value || value === ''), then: Yup.string().required('Senha é obrigatória') }),
    name: Yup.string().required('Nome é obrigatório')
  }

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getFeatures = async () => {
    setLoad(true);
    const response: AxiosResponse = await api.get(`/features/mobile`);
    if (response && response.data) {
      setPermissionList(response.data);
    }
  };
  const getDocument = async (id: string) => {
    setLoad(true);
    const response: AxiosResponse = await api.get(`/usermobile/${id}`);
    if (response && response.data) {
      const doc: IUserMobile = {
        ...response.data, 
        login: response.data?.login?.replace(/[^\d]/g, "").length > 11 ? 
                response.data.login?.replace(/[^\d]/g, "").replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5") : 
                response.data.login?.replace(/[^\d]/g, "").replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")}
      setObjectData(doc);
    }
  };
  useEffect(() => {
    async function getDataFromAPI() {
      await getFeatures();
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  useEffect(() => {
    setLoad(false);
  }, [objectData]);

  const submit = async ({login, password, name}) => {
    try {
      const dataToSave = {
        ...objectData,
        login: login.replace(/[^\w\s]/gi, ''), 
        password, 
        name,
        userPermissions: objectData.userPermissions?.filter(
          (p) => p.producer?._id && p.permissions.length > 0,
        ),
      };
      const afterActive = dataToSave.active === undefined ? true : dataToSave.active
      const saved: AxiosResponse<IUserMobile> = await api.post<IUserMobile>('/usermobile/save', dataToSave);      
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: dataId
          ? 'Registro atualizado com sucesso!'
          : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      if (saved?.data && !saved?.data.active && !!afterActive && (saved?.data.userPermissions||[]).length === 0 ){   
        Toast({
          status: 'info',
          title: 'Informação',
          description: 'Cadastro inativo por não ter permissões liberadas!',
          position: 'top-right',
        });
      }
      closeForm(true, saved.data);
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err.message,
        position: 'top-right',
      });
    }
  };

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };

  const isSelect = (producer, permission) => {
    if (
      !(
        objectData &&
        objectData.userPermissions &&
        objectData.userPermissions.length > 0
      )
    )
      return false;
    return (
      (
        objectData?.userPermissions
          ?.find((f) => f.producer._id === producer._id)
          ?.permissions?.filter((f) => f === permission.feature) || []
      ).length > 0
    );
  };

  const handleChange = (
    producer: IProducerSave,
    permission: PermissionList,
  ) => {
    const pCoopIdx = objectData?.userPermissions?.findIndex(
      (obj) => obj.producer._id === producer.producer._id,
    );
    if (pCoopIdx !== undefined) {
      const pCoop = objectData?.userPermissions[pCoopIdx];
      let idx = -1;
      if (pCoop) {
        idx = pCoop.permissions.findIndex((p) => p === permission.feature);
        if (idx >= 0) {
          pCoop.permissions.splice(idx, 1);
        } else {
          pCoop.permissions.push(permission.feature);
        }
      } else {
        const coopPermission = {
          producer: {
            _id: producer.producer._id,
            name: producer.producer.name,
          },
          permissions: [permission.feature],
        };
        addProps(objectData, 'userPermissions', []);
        objectData.userPermissions.push(coopPermission);
      }
    } else {
      const coopPermission = {
        producer: {
          _id: producer.producer._id,
          name: producer.producer.name,
        },
        permissions: [permission.feature],
      };
      addProps(objectData, 'userPermissions', []);
      objectData.userPermissions.push(coopPermission);
    }
    setObjectData(objectData);
  };

  const getProducers = async (inputValue?: string) => {
    const notIds: string[] = objectData.userPermissions?.map(
      (m) => m.producer._id,
    );
    const { data } = await api.get<IProducer[]>(
      `/producer?${inputValue ? `q=${inputValue}&` : ''}limit=8&sort=name${
        notIds ? `&nid=${notIds}` : ''
      }`,
    );
    return data?.map((r) => ({ label: r.name, value: r._id }));
  };

  const promiseOptions = (inputValue: string) => {
    return new Promise<any>((resolve) => {
      setTimeout(() => {
        return resolve(getProducers(inputValue));
      }, 1000);
    });
  };
  const addProducer = () => {
    if (
      selectedProducer &&
      !objectData.userPermissions?.find(
        (f) => f.producer._id === selectedProducer?.value,
      )
    ) {
      setObjectData({
        ...objectData,
        userPermissions: [
          ...(objectData.userPermissions || []),
          {
            producer: {
              _id: selectedProducer?.value,
              name: selectedProducer?.label,
            },
            permissions: Array<string>(),
            active: true,
          },
        ],
      });
    }
  };

  // const handleChangeSelect = (value: OptionTypeBase | null, action: ActionMeta<OptionTypeBase>) => {
  //   if (value && !objectData.userPermissions?.find(f=> f.producer._id === value?.value)){
  //     setObjectData({...objectData, userPermissions: [...objectData.userPermissions || [], { producer: { _id: value?.value, name: value?.label }, permissions: Array<string>(), active: true }]})
  //   }
  // }
  const onInputChange = (text, { action }) => {
    if (action === 'input-change') {
      setInputProducer(text);
    }
  };
  const noOptionsMessage = (objMsg) => {
    if (
      selectedProducer &&
      !objectData.userPermissions?.find(
        (f) => f.producer._id === selectedProducer?.value,
      )
    ) {
      return `Para adicionar "${selectedProducer.label}" click no bortão ao lado`;
    } else if (
      selectedProducer &&
      objectData.userPermissions?.find(
        (f) => f.producer._id === selectedProducer?.value,
      )
    ) {
      return `Cooperado "${selectedProducer.label}" já foi adicionado a lista de pemissions!`;
    } else if (inputProducer) {
      return `Não entrato nenhum cooperado em que o nome contenha "${inputProducer}"!`;
    }
    return 'Informe nome ou sobrenome do cooperado';
  };
  const onChange = (value, { action }) => {
    if (action === 'select-option') {
      setSelectedProducer({ value: value.value, label: value.label });
      setInputProducer(value.label);
    }
    if (action === 'clear') {
      setSelectedProducer(undefined);
      setInputProducer(undefined);
    }
  };
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl"
    >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader>
          {action === 'VIEW'
            ? 'Visualizar usuário'
            : dataId
            ? 'Atualizar usuário'
            : 'Novo Usuário'}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={8} overflowX="auto">        
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={formSchema}>
            <Tabs isFitted variant="enclosed">
              <TabList mb="1em">
                <Tab>Informações do usuário</Tab>
                <Tab>Permissões</Tab>
              </TabList>
              <TabPanels>
                <TabPanel>
                  <Stack spacing="4">
                  <EAInput
                    name="id"
                    isInvisible
                    defaultValue={ dataId || '' }
                  />
                  <EAInput
                    name="login"
                    label="Login"
                    isRequired
                    isReadOnly={!!dataId}
                    defaultValue={ objectData.login || ''}
                    autoComplete="none"
                    mask="CPFCNPJ"
                  />
                  {action !== 'VIEW' && (
                    <EAInput
                      name="password"
                      label="Senha"
                      isRequired={!dataId}
                      type="password"
                      defaultValue={ objectData.password || ''}
                      autoComplete="none"
                    />
                  )}
                  <EAInput
                    name="name"
                    label="Nome"   
                    isRequired       
                    isReadOnly={action === 'VIEW'}
                    defaultValue={ objectData.name || ''}
                    autoComplete="none"
                  />
                  <EAInputSwitch
                    name="newPassword"
                    label="Solicitar nova senha"
                    colorScheme="green" size="lg"
                    isReadOnly={action === 'VIEW'}
                    defaultChecked={objectData?.newPassword === undefined ? false : objectData.newPassword}
                    onChange={() => {
                      objectData.newPassword = !objectData.newPassword
                    }}
                  />     
                  <EAInputSwitch
                    name="active"
                    label="Ativo"
                    isReadOnly={action === 'VIEW'}
                    defaultChecked={objectData?.active === undefined ? true : objectData.active}
                    onChange={() => {
                      objectData.active = !objectData.active
                    }}
                  />
                    {/*
                    <SimpleInput
                      name="login"
                      placeholder="Login"
                      disabled={!!dataId}
                      savedValue={objectData.login}
                      autoComplete="off"
                      mask="CPFCNPJ"
                    />
                    {action !== 'VIEW' && (
                      <SimpleInput
                        placeholder="Password"
                        name="password"
                        savedValue={objectData.password}
                        type="password"
                        autoComplete="none"
                      />
                    )}
                    <SimpleInput
                      placeholder="Name"
                      name="name"
                      savedValue={objectData.name}
                      disabled={action === 'VIEW'}
                      autoComplete="off"
                    />
                    <HStack justifyContent="space-between" alignItems="center">
                      <Text>request new password</Text>
                      <Switch
                        isDisabled={action === 'VIEW'}
                        colorScheme="green" size="lg"
                        defaultChecked={objectData?.newPassword === undefined ? false : objectData.newPassword}
                        onChange={() => {
                          objectData.newPassword = !objectData.newPassword
                        }} />
                    </HStack>
                    <HStack justifyContent="space-between" alignItems="center">
                      <Text>Active</Text>
                      <Switch
                        isDisabled={action === 'VIEW'}
                        colorScheme="green"
                        size="lg"
                        defaultChecked={
                          objectData?.active === undefined
                            ? true
                            : objectData.active
                        }
                        onChange={() => {
                          objectData.active = !objectData.active;
                        }}
                      />
                    </HStack>
                    */
                  }
                  </Stack>
                </TabPanel>
                <TabPanel style={{ flex: 1 }}>
                  <Flex>
                    <AsyncSelect
                      placeholder="Filtre pelo nome ou sobrenome do cooperado"
                      value={selectedProducer}
                      isClearable
                      onInputChange={onInputChange}
                      onChange={onChange}
                      // hideSelectedOptions={true}
                      styles={{
                        container: (styles) => ({ ...styles, flex: 1 }),
                      }}
                      // cacheOptions
                      // defaultOptions
                      loadOptions={(inputValue: string) =>
                        promiseOptions(inputValue)
                      }
                      noOptionsMessage={noOptionsMessage}
                    />
                    <IconButton
                      colorScheme="green"
                      aria-label="Add Producer"
                      icon={<FontAwesomeIcon icon={faPlus} />}
                      styles={{}}
                      onClick={addProducer}
                    />
                  </Flex>
                  <VStack>
                    <Table>
                      <Thead>
                        <Tr alignItems="center" justifyContent="center">
                          <Th>Produtor</Th>
                          {permissionList.map((permission, idxPermission) => (
                            <Th
                              alignContent="center"
                              justifyContent="center"
                              key={idxPermission}
                            >
                              {permission.description}
                            </Th>
                          ))}
                        </Tr>
                      </Thead>
                      <Tbody>
                        {objectData?.userPermissions?.map((producer) => (
                          <Tr
                            alignItems="center"
                            justifyContent="center"
                            key={producer?._id || producer?.producer?._id}
                          >
                            <Td
                              alignSelf="center"
                              justifySelf="center"
                              key={producer?._id || producer?.producer?._id}
                            >
                              {producer.producer.name}{' '}
                            </Td>
                            {permissionList.map((permission) => (
                              <Td
                                alignItems="center"
                                justifyContent="center"
                                key={`${
                                  producer?._id || producer?.producer?._id
                                }-${permission.feature}`}
                              >
                                <Checkbox
                                  isDisabled={action === 'VIEW'}
                                  defaultValue={permission.feature}
                                  defaultChecked={isSelect(
                                    producer.producer,
                                    permission,
                                  )}
                                  onChange={() =>
                                    handleChange(producer, permission)
                                  }
                                />
                              </Td>
                            ))}
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </VStack>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </EAForm>
        </ModalBody>
        <ModalFooter>
          <Button
            isDisabled={action === 'VIEW'}
            colorScheme="green"
            mr={3}
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSubmit}
          >
            Salvar
          </Button>
          <Button
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UserMobileModal;
