import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  useDisclosure,
  HStack,
  Text,
  Heading,
  Box
} from '@chakra-ui/react';
import { AxiosResponse } from 'axios';
import api from '../../services/api';
import { IEventMobile } from '../../dtos/event-mobile';
import DateUtil from '../../utils/DataUtil';


type AuditFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IEventMobile) => void;
};
export interface PermissionList {
  description: string;
  feature: string;
}

const EventMobileModel = ({ dataId, action, onCloseForm }: AuditFormProps) => {
  const [objectData, setObjectData] = useState<IEventMobile>({} as IEventMobile);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const getDocument = async (id: string) => {
    const response: AxiosResponse = await api.get(`/eventmobile/${id}`);
    if (response && response.data) {
      const doc: IEventMobile = response.data
      setObjectData(doc);
    }
  };
  
  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }

    getDataFromAPI();
  }, []);

  function Field({ title, desc, isJson=false, ...rest }) {
    return (
      <Box p={1} shadow="md" borderWidth="1px" {...rest}>
        <Heading size="md" >{title}</Heading>
        {
          isJson ?
          <Box mt={1}>
            <pre>{JSON.stringify(desc, null, 2) }</pre>
          </Box> : 
          <Text mt={1}>{desc }</Text>
        }
      </Box>
    )
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="6xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
        <ModalHeader textAlign="center" >
          Dados do Evento
        </ModalHeader>        
        <ModalBody pb={8} overflowX="auto">          
            <Stack spacing="4">
              <HStack spacing={4}>
                <Field title="Data" flex="1" desc={DateUtil.stringFormatt(new Date(objectData?.dateOf as string), 'DD/MM/YYYY HH:mm:ss')} />
                <Field title="Versão APP" flex="1" desc={objectData.appVersion} />
                <Field title="Versão API"  flex="1" desc={objectData.apiVersion} />
                <Field title="Tipo Evento"  flex="1" desc={objectData.type} />
              </HStack>
              <HStack spacing={4}>
                <Field title="Dispositivo" flex="1" desc={objectData.deviceModel} />
                <Field title="OS Version"  flex="1" desc={objectData.deviceOSVersion} />
                <Field title="Nome Dispositivo" flex="1" desc={objectData.deviceName} />
              </HStack>
              <Field title="Usuário" desc={objectData.user?.name} />
              <Field title="Acão" desc={objectData.action} />
              <Field title="Mensagem" desc={objectData.message} />
              <Field title="Log" isJson desc={objectData.object} />         
            </Stack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" onClick={() => closeForm(false)}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default EventMobileModel;
