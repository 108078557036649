import { Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, useDisclosure, useToast } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import React, { useEffect, useRef, useState, useCallback } from 'react';
import EAForm from '../../components/Form/EAForm';
import EAInput from '../../components/Form/EAInput';
import EAInputSwitch from '../../components/Form/EAInputSwitch';
import { IParametersStock } from '../../dtos/IParametersStock';
import * as Yup from 'yup';
import api from '../../services/api';
import { AxiosResponse } from 'axios';
import EASelect, { ISelectOptions } from '../../components/Form/EASelect';
import { IProductParametersStock } from '../../dtos/IProductParametersStock';
import { ICropParametersStock } from '../../dtos/ICropParametersStock';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';


type ParametersStockModalFormProps = {
  dataId?: string;
  action: 'VIEW' | 'EDIT' | 'DEFAULT';
  onCloseForm?: (reload: boolean, newData: IParametersStock) => void;
};

export function ParametersStockModal({ action, dataId, onCloseForm }: ParametersStockModalFormProps) {
  const Toast = useToast();
  const [objectData, setObjectData] = useState<IParametersStock>({} as IParametersStock);
  const formRef = useRef<FormHandles>(null);
  const [isSaving, setIsSaving] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [reload, setReload] = useState(false);
  const [openNewApiKey, setOpenNewApiKey] = useState(false)
  const [load, setLoad] = useState(false);
  const [optionsProduct, setOptionsProduct] = useState<ISelectOptions[]>([]);

  useEffect(() => {
    setLoad(false);
  }, [objectData]);

  const getDocument = async (id: string) => {
    setLoad(true);
    const response: AxiosResponse<IParametersStock> = await api.get<IParametersStock>(`/stock-withdrawal-params/${id}`);
    if (response && response.data) {
      setObjectData(response.data);
    }
  };

  useEffect(() => {
    async function getDataFromAPI() {
      if (dataId) {
        await getDocument(dataId);
      }
      onOpen();
    }  
    getDataFromAPI();
  }, []);
  

  const submit = async ({product, crop, percentage}) => {
    try {
      const dataToSave = {
        ...objectData,
        id: objectData._id,
        product,
        crop,
        percentage
      };
      const saved: AxiosResponse<IParametersStock> = await api.post<IParametersStock>('/stock-withdrawal-params/save', dataToSave);
      Toast({
        status: 'success',
        title: 'Sucesso',
        description: dataId ? 'Registro atualizado com sucesso!' : 'Registro inserido com sucesso!',
        position: 'top-right',
      });
      closeForm(true, saved.data);
    } catch (err) {
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: err.message ,
        position: 'top-right',
      });
    }
  };

  const formSchema = {
    id: Yup.string().default(dataId),
    product: Yup.string().required('Produtor é obrigatório'),
    crop: Yup.string().required('Safra é obrigatório'),
    percentage: Yup.number().required('Porcentagem é obrigatório').min(0).max(100),
  };

  const closeForm = (reload = false, data?: any) => {
    if (onCloseForm) {
      onCloseForm(reload, data);
    }
    onClose();
  };

  const handleSubmit = () => {
    formRef.current?.submitForm();
  };

  const loadProducts = useCallback(async (text?: string)=>{
    const { data } = await api.get<IProductParametersStock[]>(
      `/stock-withdrawal-params/product/list?${text ? `q=${text}&` : ''}limit=15&sort=name
      }`,
    );
    return data.map(doc => ({ value: doc._id, label: doc.name }))
  }, [])

  

  const loadCrops = useCallback(async (text?: string)=>{
    const { data } = await api.get<ICropParametersStock[]>(
      `/stock-withdrawal-params/crop/list?${text ? `q=${text}&` : ''}limit=15&sort=crop
      }`,
    );
    return data.map(doc => ({ label: doc.crop, value: doc.crop }))
  }, [])

  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={closeForm}
      size="4xl" >
      <ModalOverlay />
      <ModalContent height="80vh">
      <ModalHeader>
        {action === 'VIEW' ? 'Visualizar parâmetro' : dataId ? 'Atualizar parâmetro' : 'Novo parâmetro'}
        </ModalHeader>
        <ModalBody pb={8} overflowX="auto">
          <EAForm formRef={formRef} onSubmit={submit} yupSchema={formSchema}>
            <Stack spacing="4">
              <EAInput
                name="id"
                isInvisible
                defaultValue={ dataId || '' }
              />
              <EASelect 
                name="product"
                label="Produto"
                isRequired
                isClearable
                placeholder="Filtre pelo produto"
                defaultValue={ objectData?.product ? { value: objectData?.product, label: objectData?.productName } : null}
                asyncSearch={loadProducts}
                styles={{
                  container: (styles) => ({ ...styles, flex: 1 })
                }}
                isReadOnly={action === 'VIEW'}
              />
              <EASelect 
                name="crop"
                label="Safra"
                isRequired
                isClearable
                placeholder="Filtre pela safra"
                defaultValue={objectData?.crop ? { value: objectData?.crop, label: objectData?.crop } : null}
                asyncSearch={loadCrops}
                styles={{
                  container: (styles) => ({ ...styles, flex: 1 })
                }}
                isReadOnly={action === 'VIEW'}
              />
              <EAInput
                name="percentage"
                label="Porcentagem"
                type='number'
                isRequired
                isReadOnly={action === 'VIEW'}
                defaultValue={ objectData?.percentage }
                autoComplete="none"
              />
              <EAInputSwitch
                name="active"
                label="Ativo"
                isReadOnly={action === 'VIEW'}
                defaultChecked={objectData?.active === undefined ? true : objectData.active}
                onChange={() => { objectData.active = !objectData.active }}
              />
            </Stack>
          </EAForm>
        </ModalBody>
        <ModalFooter>
          <Button isDisabled={action === 'VIEW' || isSaving} 
            colorScheme="green" mr={3} 
            leftIcon={<FontAwesomeIcon icon={faSave} />}
            onClick={handleSubmit}>
            Salvar
          </Button>
          <Button  
            isDisabled={ isSaving  }
            mr={3} 
            colorScheme="blackAlpha" 
            leftIcon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => closeForm(false)}>
            Fechar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
