import { IconButton, Tooltip } from '@chakra-ui/react';
import {
  faCompressArrowsAlt,
  faExpandArrowsAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AxiosResponse } from 'axios';
import React, { useEffect, useState } from 'react';
import { IDefaultResponseList } from '../../dtos/commons';

import {
  DeliveryOrderBoardDTO,
  EDeliveryOrderStatus,
} from '../../dtos/delivery-order';
import { useSidebar } from '../../hooks/Sidebar';
import api from '../../services/api';
import { extractKeyAndValueFromEnum } from '../../utils/extractKeyValueFromEnum';
import { BoardSection } from '../BoardSection';
import { Container, InternalContainer, Row, SectionHeader } from './styles';
export function Board() {
  const sidebarProvider = useSidebar();
  const [timer, setTimer] = useState<boolean>();
  const [hibeSideBar, setHibeSideBar] = useState<boolean>(false);

  useEffect(() => {
    setTimeout(() => {
      setTimer(!timer);
    }, 30000);
  }, []);

  useEffect(() => {
    sidebarProvider.setHideSideBar(hibeSideBar);
  }, [hibeSideBar]);

  const requestData = async (
    status: string,
  ): Promise<DeliveryOrderBoardDTO[]> => {
    const response: AxiosResponse<IDefaultResponseList<
      DeliveryOrderBoardDTO
    >> = await api.get(`/delivery-order/board/${status}/list`);
    return response.data?.rows;
  };

  const [emFaturamento, setemFaturamento] = useState<DeliveryOrderBoardDTO[]>(
    [],
  );
  const [emFaturamentoPrioridade, setemFaturamentoPrioridade] = useState<
    DeliveryOrderBoardDTO[]
  >([]);
  const [emSeparacao, setemSeparacao] = useState<DeliveryOrderBoardDTO[]>([]);
  const [emSeparacaoPrioridade, setemSeparacaoPrioridade] = useState<
    DeliveryOrderBoardDTO[]
  >([]);
  const [aguardandoLiberacao, setaguardandoLiberacao] = useState<
    DeliveryOrderBoardDTO[]
  >([]);

  const verifyPriority = async (
    key: string,
    setStateDefault: React.Dispatch<
      React.SetStateAction<DeliveryOrderBoardDTO[]>
    >,
    setStatePriority: React.Dispatch<
      React.SetStateAction<DeliveryOrderBoardDTO[]>
    >,
  ): Promise<void> => {
    const items: DeliveryOrderBoardDTO[] = await requestData(key);
    setStateDefault(items.filter((d) => d.priority === 9999999));
    setStatePriority(items.filter((d) => d.priority === 1));
  };

  useEffect(() => {
    async function getData() {
      await verifyPriority(
        'IN_SEPARATION',
        setemSeparacao,
        setemSeparacaoPrioridade,
      );
      await verifyPriority(
        'WAITING_BILLING',
        setemFaturamento,
        setemFaturamentoPrioridade,
      );
      setaguardandoLiberacao(await requestData('WAITING_RELEASE'));
    }

    getData();
  }, [timer]);

  return (
    <Container>
      <Tooltip
        label={!hibeSideBar ? 'Expandir' : 'Comprimir'}
        placement="left-start"
      >
        <IconButton
          aria-label={!hibeSideBar ? 'Full Screem' : ''}
          onClick={() => setHibeSideBar(!hibeSideBar)}
          position="fixed"
          variant="outline"
          colorScheme={'linkedin'}
          bottom="4"
          right="4"
          size={'lg'}
          borderRadius="50%"
          icon={
            <FontAwesomeIcon
              icon={!hibeSideBar ? faExpandArrowsAlt : faCompressArrowsAlt}
            />
          }
        />
      </Tooltip>
      <InternalContainer
        style={{
          flex: 2.1,
        }}
      >
        <SectionHeader>Aguardando</SectionHeader>
        <BoardSection
          state={extractKeyAndValueFromEnum(
            EDeliveryOrderStatus,
            'WAITING_RELEASE',
          )}
          data={aguardandoLiberacao || []}
        />
      </InternalContainer>
      <InternalContainer
        style={{
          flex: 4,
        }}
      >
        <SectionHeader>Normal</SectionHeader>
        <Row>
          <BoardSection
            state={extractKeyAndValueFromEnum(
              EDeliveryOrderStatus,
              'IN_SEPARATION',
            )}
            data={emSeparacao || []}
          />
          <BoardSection
            state={extractKeyAndValueFromEnum(
              EDeliveryOrderStatus,
              'WAITING_BILLING',
            )}
            data={emFaturamento || []}
          />
        </Row>
      </InternalContainer>
      <InternalContainer
        style={{
          flex: 4,
        }}
      >
        <SectionHeader>Prioridade</SectionHeader>
        <Row>
          <BoardSection
            state={extractKeyAndValueFromEnum(
              EDeliveryOrderStatus,
              'IN_SEPARATION',
            )}
            data={emSeparacaoPrioridade || []}
          />
          <BoardSection
            state={extractKeyAndValueFromEnum(
              EDeliveryOrderStatus,
              'WAITING_BILLING',
            )}
            data={emFaturamentoPrioridade || []}
          />
        </Row>
      </InternalContainer>
    </Container>
  );
}
