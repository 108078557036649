import { Button, ButtonGroup, IconButton } from '@chakra-ui/button';
import { FormControl, FormErrorMessage } from '@chakra-ui/form-control';
import { HStack, Spacer, Stack } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';
import { Tooltip } from '@chakra-ui/tooltip';
import { faPlay, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from '@unform/core';
import { darken } from 'polished';
import React, { InputHTMLAttributes, useCallback, useEffect, useRef, useState } from 'react';
import EAConfirmationDialog from '../../EAConfirmationDialog';
import EAModalVideoPlayer from '../EAModalVideoPlayer';

export interface InputFile {
  fileId?: string
  fileName?: string
  filePath?: string
  file?: File,
  mimetype?: string
  qualities?: string[]
}

interface EAInputUploadProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  file?: InputFile
  isReadOnly?: boolean
  isDisabled?: boolean
  isRequired?: boolean
  deleting?: boolean
  uploading?: boolean
  uploadingProgress?: number
  isVideoPlayer?: boolean
  fileActionInput?: (file: InputFile) => void;
  fileActionDelete?: (file: InputFile) => void;
}

const EAInputUpload: React.FC<EAInputUploadProps> = (props: EAInputUploadProps) => {
  const {
  name,
  file,
  label,
  isReadOnly,
  isDisabled,
  isRequired,
  deleting,
  uploading,
  uploadingProgress,
  isVideoPlayer,
  fileActionInput= (_file: InputFile) => {},
  fileActionDelete= (_file: InputFile) => {},
  ...rest
  } = props
  const [fileInput, setFileInput] = useState<InputFile>(file || {});
  const { fieldName, error, registerField } = useField(name);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [isPlay, setIsPlay] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);

  }, []);
  
  const onClickDelete = useCallback((e) => {
    if (fileActionDelete)
      fileActionDelete(e)
  }, []);
  const onFileSelected = useCallback((event) => {
    // console.log('EaImput: ', event)
    let selected: InputFile = {};
    if (event && event.target && event.target.files && event.target.files.length > 0){      
      const fl: File = event?.target?.files[0]
      const path = URL.createObjectURL(fl)
      selected = {
        fileName: fl.name,
        file: fl,
        fileId: undefined,
        mimetype: fl.type,
        filePath: path
      }

    }
    // console.log('EaImputSetFileInput: ', selected)
    setFileInput(selected)
    fileActionInput(selected)
  }, []);

  
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
      <>
      <Stack direction="row" spacing={4} w="100%">
        <ButtonGroup
          isAttached
          width="100%"
          >      
          <Tooltip label={fileInput.fileName ? "Alterar Arquivo" : "Selecione Arquivo!"}>
            <Button
              id={"file-upload-"+name}
              leftIcon={<FontAwesomeIcon icon={faUpload} />}
              width="100%"
              _hover={{
                color: darken(0.2,'#666360'),
                background: (isReadOnly ? darken(0.2, '#FFF') : '#FFF')
              }}
              isDisabled={deleting}
              justifyContent="flex-start"
              color={'#666360'}
              background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
              padding="16px"
              display="flex"
              border="1px solid"
              borderColor="inherit"
              isLoading={uploading}
              loadingText={fileInput.fileName}
              borderRadius="10px"
              onClick={()=> inputRef.current?.click()}
              >
              <span style={{marginLeft: '8px'}} >{ fileInput.fileName || 'Nenhum arquivo selecionado!'}</span>
                    
              <input
                style={{
                  display: 'none'
                }}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                id={"file-upload-"+name}
                type="file"
                ref={inputRef}
                disabled={isReadOnly || false}
                accept={rest.accept}
                onChange={ (e)=> onFileSelected(e)}
              />
            </Button>
          </Tooltip>
          <Spacer/>          
          <EAConfirmationDialog
            isOpenDialog={isConfirmationDialogOpen}
            textHeader="Exclusão de Arquivo"
            textBoby={`Confirma a exclusão o arquivo (${fileInput.fileName})?`}
            textActionOne="Sim"
            actionOneProps={{
              colorScheme:"red",
              onClick: () => {
                setIsConfirmationDialogOpen(false)
                onClickDelete(fileInput)
              }
            }}
            textActionTwo="Não"
            actionTwoProps={{
              onClick: () => {
                setIsConfirmationDialogOpen(false)
              }
            }}
          />
          {
            isVideoPlayer && fileInput && fileInput.filePath ?
            <>
              <Tooltip label="Reproduzir Video">
                <IconButton
                  aria-label="Play"
                  padding="16px"
                  icon={<FontAwesomeIcon icon={faPlay} size="lg" />}
                  onClick={()=> setIsPlay(true) }
                />
              </Tooltip>              
              { isPlay ? 
                  <EAModalVideoPlayer 
                    title={fileInput.fileName}
                    videoMimetype={fileInput.mimetype}
                    videoUrl={fileInput.filePath}
                    onCloseForm={()=> setIsPlay(false)}
                  />              
                : null}
            </>
            :
            null
          }
          <Tooltip label="Excluir Arquivo!">
            <IconButton
              aria-label="Excluir"
              isDisabled={isReadOnly}
              colorScheme="red"
              padding="16px"
              //isLoading={deleting}
              icon={<FontAwesomeIcon icon={faTrash} size="lg" />}
              onClick={()=> fileInput.fileName ? setIsConfirmationDialogOpen(true) :  onClickDelete(fileInput)}
            />
          </Tooltip>
        </ButtonGroup>
      </Stack>
      { uploading ? <Progress hasStripe margin="0px" borderRadius="10px" value={uploadingProgress} /> : null }
    </>
  )
};

export default EAInputUpload;
