import { Button, ButtonGroup, IconButton } from '@chakra-ui/button';
import { InputProps } from '@chakra-ui/input';
import { Spacer, Stack } from '@chakra-ui/layout';
import { Progress } from '@chakra-ui/progress';
import { Tooltip } from '@chakra-ui/tooltip';
import { faDownload, faPlay, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useField } from '@unform/core';
import { darken } from 'polished';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import EAConfirmationDialog from '../../EAConfirmationDialog';
import EAModalVideoPlayer from '../EAModalVideoPlayer';

interface InputFile {
  fileId?: string
  fileName?: string
  filePath?: string
  mimetype?: string
  file?: File
}

export interface EAInputDownloadProps extends InputProps {
  name: string;
  file?: InputFile
  downloding?: boolean
  downlodingProgress?: number
  deleting?: boolean
  isVideoPlayer?: boolean
  urlVideoPlayer?: string
  fileActionDownload?: (file: InputFile) => void;
  fileActionDelete?: (file: InputFile) => void;
}

const EAInputDownload: React.FC<EAInputDownloadProps> = (props: EAInputDownloadProps) => {
  const {
  name,
  file,
  isReadOnly,
  isRequired,
  downloding,
  downlodingProgress,
  deleting,
  isVideoPlayer,
  urlVideoPlayer,
  fileActionDownload,
  fileActionDelete,
  ...rest
  } = props
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, registerField } = useField(name);
  const [fileInput, ] = useState<InputFile>(file || {});
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false);
  const [isPlay, setIsPlay] = useState(false);

  const onClickDownload = useCallback((e: InputFile) => {
    if (fileActionDownload)
      fileActionDownload(e)
  }, []);

  const onClickDelete = useCallback((e) => {
    if (fileActionDelete)
      fileActionDelete(e)
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);
  return (
    <>
      <Stack direction="row" spacing={4} w="100%">
        <ButtonGroup
          isAttached
          width="100%"
          >
          <Tooltip label="Baixar Arquivo!">
            <Button
              id={"file-upload-"+fieldName}
              leftIcon={<FontAwesomeIcon icon={faDownload} />}
              width="100%"
              _hover={{
                color: darken(0.2,'#666360'),
                background: (isReadOnly ? darken(0.2, '#FFF') : '#FFF')
              }}
              isDisabled={deleting}
              justifyContent="flex-start"
              color={'#666360'}
              background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
              padding="16px"
              display="flex"
              border="1px solid"
              borderColor="inherit"
              isLoading={downloding}
              loadingText={fileInput.fileName}
              borderRadius="10px"
              onClick={()=> onClickDownload(fileInput)}
              >
              <span style={{marginLeft: '8px'}} >{ fileInput.fileName || 'Nenhum arquivo selecionado!'}</span>
            </Button>
          </Tooltip>
          <Spacer/>
          <EAConfirmationDialog
            isOpenDialog={isConfirmationDialogOpen}
            textHeader="Exclusão de Arquivo"
            textBoby={`Confirma a exclusão o arquivo (${fileInput.fileName})?`}
            textActionOne="Sim"
            actionOneProps={{
              colorScheme:"red",
              onClick: () => {
                setIsConfirmationDialogOpen(false)
                onClickDelete(fileInput)
              }
            }}
            textActionTwo="Não"
            actionTwoProps={{
              onClick: () => {
                setIsConfirmationDialogOpen(false)
              }
            }}
          />          
          {
            isVideoPlayer && urlVideoPlayer ?
            <>
              <Tooltip label="Reproduzir Video">
                <IconButton
                  aria-label="Play"
                  padding="16px"
                  icon={<FontAwesomeIcon icon={faPlay} size="lg" />}
                  onClick={()=> setIsPlay(true) }
                />
              </Tooltip>            
              { isPlay ? 
                <EAModalVideoPlayer
                  title={fileInput.fileName}
                  videoMimetype={fileInput.mimetype}
                  videoUrl={urlVideoPlayer}
                  onCloseForm={()=> setIsPlay(false)}
                />              
              : null}
            </>
            :
            null
          }
          <Tooltip label="Excluir Arquivo!">
            <IconButton
              aria-label="Excluir"
              isDisabled={isReadOnly || isConfirmationDialogOpen}
              colorScheme="red"
              padding="16px"
              isLoading={deleting}
              icon={<FontAwesomeIcon icon={faTrash} size="lg" />}
              onClick={()=> setIsConfirmationDialogOpen(true)}
            />
          </Tooltip>
        </ButtonGroup>
      </Stack>
      { downloding ? <Progress hasStripe mt="0px" borderRadius="10px" value={downlodingProgress} /> : null }
    </>
  );
};

export default EAInputDownload;
