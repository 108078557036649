import { extendTheme } from '@chakra-ui/react';
import { darken } from 'polished';

export const theme = extendTheme({
  colors: {
    primary: darken(0.5, '#18c954'),
    secondary: '#eacc81',
    gray: {
      '900': '#41414A',
      '800': '#41414A',
      '700': '#9898A6',
      '600': '#A9A9B8',
      '500': '#C5C5D6',
      '400': '#D3D3E2',
      '300': '#DBDBE8',
      '200': '#E6E6F0',
      '100': '#F5F5FA',
      '50': '#FFFFFF',
    },
    dark: '#1e1e1e',
    darkGray: '#41414A',
    // gray: {
    //   '900': darken(0.9, '#41414A'),
    //   '800': '#41414A',
    //   '700': lighten(0.2, '#41414A'),
    //   '600': lighten(0.3, '#41414A'),
    //   '500': lighten(0.4, '#41414A'),
    //   '400': lighten(0.5, '#41414A'),
    //   '300': lighten(0.6, '#41414A'),
    //   '200': lighten(0.7, '#41414A'),
    //   '100': lighten(0.8, '#41414A'),
    //   '50': lighten(0.9, '#41414A'),
    // },
    white: '#FFFFFF'
  },
  fonts: {
    body: 'Roboto',
    heading: 'Roboto',
  },
  // styles: {
  //   global: {
  //     body: {
  //       bg: 'gray.100',
  //       color: 'gray.600',
  //       i: {
  //         color: 'white',
  //       },
  //       table: {
  //         color: 'gray.900',
  //       },
  //     },
  //   },
  // },
});
