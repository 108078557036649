import { Button, Flex, Stack, useToast } from '@chakra-ui/react';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import ECoop from '../../assets/images/ECoop/e-coop_logo-01.svg';
import Input from '../../components/Form/Input';
import { useAuth } from '../../hooks/AuthContext';

type SignInFormData = {
  login: string;
  password: string;
};

const SignIn = () => {
  const { register, handleSubmit } = useForm();
  const { signIn } = useAuth();
  const Toast = useToast();
  const history = useHistory();

  const submit: SubmitHandler<SignInFormData> = async (data) => {
    try {
      const schema = Yup.object().shape({
        login: Yup.string().required('Login é obrigatória'),
        password: Yup.string().required('Senha obrigatória'),
      });

      await schema.validate(data, {
        abortEarly: false,
      });

      await signIn({
        login: data.login,
        password: data.password,
      });

      history.push('/home');
    } catch (err) {
      // console.error('SignInFormData-err: ', err);
      Toast({
        status: 'error',
        title: 'Erro na autenticação',
        description: `${
          err?.message ||
          'Ocorreu um erro ao fazer login, cheque as credenciais'
        }`,
        position: 'top-right',
      });
    }
  };

  return (
    <Flex align="center" w="100vw" h="100vh" bg="gray.200">
      <Flex w="40vw" align="center" justify="center">
        <Flex
          as="form"
          w="40vw"
          maxWidth={360}
          flexDir="column"
          bg="gray.800"
          borderRadius="8"
          p="8"
          onSubmit={handleSubmit(submit)}
        >
          <Stack spacing="4">
            <Input placeholder="Login" {...register('login')} />
            <Input
              placeholder="Password"
              type="password"
              {...register('password')}
            />
          </Stack>
          <Button type="submit" mt="4" colorScheme="green">
            Entrar
          </Button>
        </Flex>
      </Flex>
      <Flex bg="gray.800" w="60vw" h="100vh" padding="32">
        <img src={ECoop} />
      </Flex>
    </Flex>
  );
};

export default SignIn;
