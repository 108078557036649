import React, { useRef, useState } from 'react';
import { AlertDialog, AlertDialogBody, AlertDialogContent, AlertDialogFooter, AlertDialogHeader, AlertDialogOverlay, AlertDialogProps } from '@chakra-ui/modal';
import { Button, ButtonProps } from '@chakra-ui/button';

interface EAConfirmationDialogProps {
  isOpenDialog: boolean
  textHeader?: string
  textBoby?: string
  textActionOne?: string
  textActionTwo?: string
  textActionThree?: string
  actionOneProps ?: ButtonProps
  actionTwoProps ?: ButtonProps
  actionThreeProps ?: ButtonProps
}

const EAConfirmationDialog: React.FC<EAConfirmationDialogProps> = (props) => {
  const { 
    textHeader, 
    textBoby, 
    textActionOne, 
    textActionTwo, 
    textActionThree, 
    actionOneProps = {}, 
    actionTwoProps = {},
    actionThreeProps = {}, 
    isOpenDialog } = props
  const actionRef = useRef(null)
  return (    
    <AlertDialog
      isOpen={isOpenDialog}
      leastDestructiveRef={actionRef}
      onClose={()=>{}}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {textHeader || 'Confirmação!'}
          </AlertDialogHeader>

          <AlertDialogBody>
            {textBoby || 'Confirma Operação?'}
          </AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={actionRef} {...actionOneProps}>
              {textActionOne || 'Ok'}
            </Button>
            { textActionTwo &&  <Button {...actionTwoProps} ml={3} >{textActionTwo} </Button> }
            { textActionThree &&  <Button {...actionThreeProps} ml={3} >{textActionThree}</Button> }
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  )
};

export default EAConfirmationDialog;
