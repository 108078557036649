import React from 'react';
import {
  DeliveryOrderBoardDTO,
  EDeliveryOrderStatus,
} from '../../dtos/delivery-order';
import calculateBusinessHours from '../../utils/calculateBusinessHours';
import {
  Container,
  ContainerOrderNumber,
  Header,
  TagRow,
  TextOrderNumber,
  TextTime,
} from './styles';

interface ICardProps {
  data: DeliveryOrderBoardDTO;
  index: number;
}

//verde: liberado/aguardando emissao
//laranja: 1h

export function BoardCard({ data, index }: ICardProps) {
  const verifyExceededTime = () => {
    const time =
      new Date().getTime() - new Date(data.createdAt || data.dateOf).getTime();
    if (
      data.status !== ('WAITING_RELEASE' as EDeliveryOrderStatus) &&
      time > 1000 * 60 * 90
    ) {
      return true;
    }
    return false;
  };

  return (
    <Container verifyExceededTime={() => verifyExceededTime()}>
      <Header>{data.producerName}</Header>
      <TagRow>
        <ContainerOrderNumber>
          <TextOrderNumber>{data.code}</TextOrderNumber>
        </ContainerOrderNumber>
        <div>
          <TextTime>{`No status ${calculateBusinessHours(
            new Date(data.dateStatus),
          )}`}</TextTime>
          <TextTime>{`Ordem aberta ${calculateBusinessHours(
            new Date(data.createdAt || data.dateOf),
          )}`}</TextTime>
        </div>
      </TagRow>
    </Container>
  );
}
