import React from 'react';
import * as Yup from 'yup';
import { useToast } from '@chakra-ui/react';
import { Form } from '@unform/web';
import { FormHandles, FormHelpers, FormProps } from '@unform/core';
import { ObjectShape } from 'yup/lib/object';

interface EAFormContextData extends FormProps {
  children: React.ReactNode
  formRef: React.RefObject<FormHandles>
  yupSchema?: ObjectShape
}
const EAForm = ({ children, yupSchema, formRef, onSubmit = (data: any, helpers: FormHelpers, event?: React.FormEvent<Element> | undefined) => {} }: EAFormContextData) => {
  const Toast = useToast();
  // console.log('init EAForm. formRef: ', formRef)
  // console.log('init EAForm. yupSchema: ', yupSchema)

  const submit = async (data: any, helpers: FormHelpers, event: React.FormEvent<Element> | undefined ) => {
    // console.log()
    // console.log('EAForm - submit data: ', data)
    // console.log('EAForm - submit formRef: ', formRef)
    // console.log('EAForm - submit yupSchema: ', yupSchema)
    formRef.current?.setErrors({});
    if (!yupSchema)
      return;
    const schema = Yup.object().shape(yupSchema);
    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      if (onSubmit)
        onSubmit(data, helpers, event )
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach(error => {
          validationErrors[error?.path || ''] = error.message;
        });
        formRef.current?.setErrors(validationErrors);
      }
      Toast({
        status: 'error',
        title: 'Erro ao salvar dados!',
        description: 'Verifique os campos do formulario',
        position: 'top-right',
      });
    }
  };
  return (
    <Form ref={formRef} onSubmit={submit}>{children}</Form>
  );
}
export default EAForm;
