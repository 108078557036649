import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Textarea, TextareaProps } from '@chakra-ui/react';
import { useField } from '@unform/core';
import { darken } from 'polished';
import React, { useCallback, useEffect, useRef } from 'react';

interface EATextareaProps extends TextareaProps {
  name: string
  label?: string
}

const EATextarea: React.FC<EATextareaProps> = ({ name, label, isReadOnly, isRequired, onChange = (e) => {}, ...props }) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const { fieldName, error, registerField } = useField(name);


  const handleOnChange = useCallback((event) => {
    event.target.style.height = (event.target.scrollHeight)+"px";
    onChange(event)
  }, []);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textAreaRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <FormControl isInvalid={!!error} isRequired={isRequired} size="md" isReadOnly={isReadOnly}>
      <FormLabel htmlFor={"textarea-"+fieldName} size="md" >{label}</FormLabel>
      <Textarea
        id={"textarea-"+fieldName }
        ref={textAreaRef}
        size="md"
        color={(isReadOnly ? darken(0.2,'#666360') : '#41414A')}
        background={(isReadOnly ? darken(0.2, '#FFF') : '#FFF')}
        resize="none"
        overflow="hidden"
        onChange={handleOnChange}
        {...props}
      />
      <FormErrorMessage>{error}</FormErrorMessage>
    </FormControl>
  )
};

export default EATextarea;
